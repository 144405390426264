.custom-modal {
  &.modal-dialog {
    width: 392px;
    min-height: 100vh;
    height: 100%;
    margin: 0 24px 0 calc(100% - 392px);
    border-radius: 6px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.13), 0 0 2px 0 rgba(0, 0, 0, 0.05);
    @media (max-width: 576px) {
      width: 392px;
      margin: 0 0 0 calc(100% - 392px);
    }
    @media (max-width: 414px) {
      width: 372px;
      margin: 0 0 0 calc(100% - 372px);
    }
    @media (max-width: 375px) {
      width: 342px;
      margin: 0 0 0 calc(100% - 342px);
    }
    .modal-content {
      height: 100%;
    }
    .modal-body {
      max-height: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .modal-header {
      padding: 16px 16px 16px 16px;
      border-bottom: none;
      @media screen and (orientation: landscape) {
        padding: 16px 16px 24px 16px;
      }
      .close {
        font-size: 36px;
        font-weight: normal;
        line-height: 24px;
        padding: 0 2px;
        margin: 0;
      }
    }
  }
}
