.company-kpi {
  margin: 0;

  &__title,
  &__label {
    font-style: normal;
    font-weight: bold;
  }

  &__title {
    font-size: 16px;
    color: $blue-7;
  }

  &__label {
    font-size: 14px;
    color: $blue-dark;
  }

  &__icon {
    position: relative;
    width: 42px;
    height: 42px;
    color: $blue-8;
    font-size: 16px;
    background: $white;
    border: 1px solid $gray;
    border-radius: 50%;

    > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__edit {
    .action-wrapper {
      margin-top: -4px;
    }

    .edit-pen {
      cursor: pointer;
      visibility: hidden;
    }

    &:hover {
      .edit-pen {
        visibility: visible;
      }
    }
  }

  // override .media-object styles.
  .media-object-section:first-child {
    padding-right: 18px;
  }
}
