@import '../../../../assets/scss/antd/variables';


.search-header-content {
  display: flex;
  align-items: center;



  .rc-overflow{
    position: relative;
    max-width: calc(100% - 50px);
  }

  > * {
    white-space: nowrap;
    margin-left: auto;
  }

  .antd-search-input {
    margin: 10px 8px 10px 0;
    min-width: 384px;
    max-width: 500px;
    flex: 1;
  }

  &.contacts-header-content {
    .ant-space .ant-space-item:first-of-type {
      flex-grow: 1;
    }
  }
}

