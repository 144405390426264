$font-family: 'SF Pro Display', -apple-system, 'Helvetica Neue', Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// colors
$black-emphasis: rgba(0, 0, 0, 0.87);

$gray: #90a0b7;
$gray-2: #fafafa;
$gray-4: #e8e8e8;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #262626;
$gray-10: #87919f;

$blue-1: #e6f7ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-dark: #334d6e;
$gray-icon: #c2cfe0;

$green-6: #52c41a;

$red-6: #f5222d;

// KPI colors
$kpi-value-color: #c2cfe0;
$kpi-label-color: #334d6e;
