@import '../../../../assets/scss/antd/variables';

// search input
.antd-search-input {
  &.ant-input-affix-wrapper {
    // padding: 15px 0;
    border-color: transparent;
  }
}

.ant-input-affix-wrapper {
  &.search-term-error {
    border-color: $red-6;
  }
}

.search-header-content {
  display: flex;
  align-items: center;

  > * {
    white-space: nowrap;
    margin-left: auto;
  }

  .antd-search-input {
    margin: 10px 8px 10px 0;
    min-width: 384px;
    max-width: 500px;
    flex: 1;
  }

  &.contacts-header-content,
  &.analysts-header-content,
  &.addons-header-content {
    .ant-space .ant-space-item:first-of-type {
      flex-grow: 1;
    }
  }
}

.tags-wrapper {
  overflow: hidden;
  position: relative;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  line-height: 1;

  > * {
    margin-left: auto;
  }

  .tag-more-filters {
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-tag {
    margin: 0 10px 0 0;
  }

  > .hide {
    position: absolute;
    left: 9999px;
    visibility: hidden;
  }

  &.hide-content > * {
    display: none;
  }
}

.recentsearches-list {
  padding: 10px 0 20px;

  .recentsearches-title .ant-dropdown-menu-item-group-title {
    font-size: 10px;
    color: $gray;
    padding-bottom: 10px;
  }

  .ant-dropdown-menu-item-group-list {
    margin: 0;
  }

  .ant-dropdown-menu-title-content,
  .ant-dropdown-menu-item {
    color: $gray-8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background: $blue-1;
    }

    .recentsearches-icon {
      color: $blue-6;
      font-size: 14px;
    }

    .item-truncate {
      max-width: 350px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
    }
  }
}

.rc-overflow {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 100%;
  width: 100%;
  position: relative;

  &-item {
    flex: none;
    max-width: 100%;
  }
}
