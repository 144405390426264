@import '../../assets/scss/antd/variables';

.company-notes {
  .ant-drawer-content-wrapper {
    min-width: 376px;
  }

  .ant-drawer-header {
    background: $gray-2;

    .ant-drawer-title {
      font-weight: normal;
    }
  }

  .ant-drawer-body {
    padding: 0 24px;
    .company-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 22px 0;

      button {
        margin-left: 10px;
      }
    }
    .company-info {
      text-align: center;
      padding: 20px 0;
    }
    .company-logo {
      width: 96px;
      height: 96px;
      margin: 0 auto;
      border-radius: 24px;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14);
      background-color: #f4f4f8;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .company-name {
      font-size: 22px;
      text-align: center;
      color: #364862;
      margin-top: 16px;
    }
    .company-notification {
      max-height: 300px;
      min-height: 100px;
      overflow: -moz-scrollbars-vertical;
      overflow-x: hidden;
      overflow-y: auto;
      margin: 24px 0;
    }
    .company-box-reply {
      width: 100%;
      .company-box-reply__title {
        font-size: 14px;
        color: #707f96;
        .count-word {
          float: right;
        }
      }
      textarea {
        width: 100%;
        height: 113px;
        opacity: 0.4;
        border-radius: 4px;
        border: solid 1px #b8ccd8;
        background-color: #f5f7fa;
        padding: 16px;
        margin: 8px 0;
        font-size: 16px;
      }
    }
  }
}
.modal.fade.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal.fade {
  .modal-dialog {
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
}
.fade {
  transition: opacity 0.2s linear;
}

.card-notification {
  border-bottom: solid 1px #b8ccd8;
  position: relative;
  padding: 0px 5px 5px 5px;
  &:hover {
    background-color: #f5f7fa;
  }
  .card-notification__user {
    position: sticky;
    padding: 12px 0px 12px 5px;
    top: 0;
    background-color: #f5f7fa;
    .card-notification__avatar {
      width: 34px;
      height: 34px;
      border-radius: 8px;
      background-color: #444;
      float: left;
      margin-right: 8px;
      img {
        width: 100%;
      }
    }
    .card-notification__info {
      display: block;
      overflow: hidden;

      .card-notification__name {
        font-size: 16px;
        color: #000000;
        line-height: 1.2;
      }
      .card-notification__time {
        font-size: 12px;
        color: #707f96;
        line-height: 1.2;
        margin-top: 2px;
      }
    }
  }
  .card-notification__message {
    font-size: 16px;
    white-space: pre-wrap;
    color: #364862;
    margin-top: 16px;
  }
}
