.dropdown-btn {
  display: inline-block;
  .btn {
    background: #fff;
    border: none;
    margin-right: -0.75rem;
    i {
      color: #c2c8cf;
    }
  }
  .dropdown-menu {
    min-width: 180px;
    padding: 0;
  }
  .dropdown-item {
    letter-spacing: 0.18px;
    color: #1967ae;
    padding: 15px 28px;
    i {
      font-size: 18px;
      color: #1866ad;
      vertical-align: baseline;
      margin-right: 16px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #e4e6ed;
    }
  }
}

.dropdown-block {
  ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  .btn {
    background-color: #fff;
    border: none;
    padding: 0;
  }
  .dropdown-menu {
    min-width: 194px;
    padding: 0;
    @media screen and (orientation: landscape) {
      z-index: 1031;
    }
  }
  .dropdown-item {
    font-size: 15px;
    font-weight: 500;
    color: #1967ae;
    padding: 20px 24px;
    i,
    svg {
      font-size: 24px;
      margin-right: 30px;
    }
  }
  &.dropdown-tags {
    .dropdown > button {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: #d5dce2;
      color: #1967ae;
      position: relative;
      font-size: 16px;
      line-height: 1;
      font-weight: bold;

      &.active {
        background-color: #1967ae;
        color: #fff;
      }
    }
    .dropdown-menu {
      min-width: 194px;
    }
  }
  &.dropdown-input {
    .dropdown > button {
      background-color: #fff;
      visibility: hidden;
      position: absolute;
      top: -8px;
      left: 0;
      z-index: -1;
    }
    .dropdown-menu {
      min-width: auto;
    }
    &.reset {
      .dropdown > button {
        background-color: #1967ae;
        visibility: initial;
        position: static;
      }
    }
  }
}
