$navigation-width: 256px;

  .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled),
  .ant-menu .ant-tooltip-open:hover,
  .ant-btn.ant-btn-text.ant-dropdown-trigger > span:hover,
  .ant-btn.ant-btn-text:hover,
  .ant-tabs-nav-operations:hover,
  .ant-tabs-dropdown-menu-item:hover,
  .ant-dropdown-menu-item.ant-menu-title-selected {
    color:#1890ff;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu-item.ant-menu-item-selected {
    color:#1890ff;
  }

  .ant-select-item-option-content:hover{
    color:#1890ff;
  }

  .multiselect__menu-list :first-child:hover,
  .multiselect__option.multiselect__option--is-focused:hover{
    color:#1890ff;
  }

  .singleselect-select__option.singleselect-select__option--is-focused:hover{
    color:#1890ff !important;
  }

.main-navigation {
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;

  .main-navigation__menu-top {
    margin-bottom: 58px;
  }

  .main-navigation__menu-custom-item .ant-menu-title-content {
    .ant-menu-title-content-inner {
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-right: 16px;
    }

    .notifications-anticon-wrapper {
      margin-right: 0;
    }
  }

  .ant-layout-sider-children {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &.ant-layout-sider {
    position: fixed;
  }

  .ant-layout-sider-trigger {
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.2s ease;

    .anticon {
      margin: 0 8px 0 0;
    }
  }

  &.ant-layout-sider-collapsed {
    .ant-layout-sider-trigger {
      .anticon {
        margin: 0;
        transform: rotate(180deg);

        & + * {
          display: none;
        }
      }
    }

    .ant-badge {
      &.ant-badge--notifications {
        right: 40%;
      }
    }

    .ant-menu-inline-collapsed {
      > .ant-menu-item {
        .notifications-anticon-wrapper {
          & + span {
            display: inline-block;
            max-width: 0;
            opacity: 0;
          }

          .circle {
            max-width: none;
            opacity: 1;
            right: -2px;
            top: -1px;
          }
        }
      }
    }

    .main-navigation__menu-custom-item .ant-menu-title-content-inner {
      padding-left: 30px;
    }
  }
}

.notifications-anticon-wrapper {
  position: relative;

  .circle {
    color: #ff4d4f;
    position: absolute;
    right: 30%;
    top: -1px;
  }
}

.ant-badge {
  &.ant-badge--notifications {
    position: absolute;
    right: 43%;
    top: calc(-100% + 5px);

    .ant-badge-count {
      box-shadow: none;
    }
  }
}
