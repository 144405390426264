.score-trending {
  font-weight: 600;
  text-align: center;
  display: inline-block;
  white-space: nowrap;

  &__growth-score {
    // blue-6
    color: #1890ff;
    margin: 0 0 0 5px;
    font-size: 1rem;
  }

  &__label {
    // dark blue
    color: #334d6e;
    font-size: 1rem;
  }
}
