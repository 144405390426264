.filter__wrapper {
  &.outreach {
    .histogram-wrapper {
      visibility: hidden !important;
      width: 0 !important;
      height: 0 !important;
    }
  }

  .filter-submit-wrapper {
    background: #fff;
    position: sticky;
    z-index: 3;
    top: 0;

    .filter-submit__search-input {
      width: 100%;

      .ant-input-affix-wrapper {
        padding: 11px 40px;
        border-left: 0;
        border-right: 0;
      }
    }

    .filter-submit__title-and-buttons {
      padding: 20px 0;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .filter-single-select {
    .ant-form-item {
      margin-bottom: 0px;
    }
  }

  .filter-form__card {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

    .ant-card-body {
      padding: 20px;

      .ant-card-meta-detail {
        overflow: visible;
      }

      .ant-card-meta-title {
        font-size: 14px;
      }

      .ant-card-meta-description {
        position: relative;
      }
    }
  }

  // Fix modal overflow scroll due to row inside form.
  // Overflow has to be added here otherwise if is added to modal sticky position will not work.
  > .ant-form {
    overflow: hidden;
  }
}

@include media-breakpoint-only(lg) {
  .filter__wrapper {
    .multi-select-async {
      .ant-card-meta-title {
        padding-bottom: 10px;
      }
    }
  }
}
