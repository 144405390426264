.no-wrap-checkbox {
  // width must be present in order to label overflow work
  // so, to avoid empty space being clickable we need to 
  // disable the pointer events on the parent and enable 
  // them on the children
  width: 100%;
  cursor: default;
  pointer-events: none;

  > * {
    cursor: pointer;
    pointer-events: auto;
  }

  .ant-checkbox + span {
    display: inline-block;
    max-width: calc(100% - 16px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;

    // if it contains Text inside
    & > * {
      max-width: 100%;
    }
  }
}

.filter-form__card {
  margin-bottom: 10px;
  max-width: 540px;
  .ant-slider-step {
    .ant-slider-dot {
      background-color: #f0f0f0;
      width: 10px;
      height: 10px;
      top: -3px;

      &.ant-slider-dot-active {
        background-color: #8cc8ff;
      }
    }
  }

  .ant-slider-with-marks {
    margin-bottom: 10px;

    .ant-slider-mark {
      display: none;
    }
  }
}

.filter-histogram {
  width: 100%;
  height: 80px;
  box-sizing: border-box;

  &.histogram-hidden {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

// This makes the first company_owner_id--multiselec option bold
// Only if the MultiSelect allowSelectAll is true
.company_owner_id--multiselect {
  .multiselect__menu-list--is-multi {
    .multiselect__option:nth-of-type(2) label {
      font-weight: bold;
    }
  }
}

.addon--multiselect-async {
  .multiselect-select__menu-list {
    .multiselect-select__option:nth-of-type(1) label {
      font-weight: bold;
    }
  }
}

.sector--multiselect {
  .multiselect__menu-list--is-multi {
    .multiselect__option:nth-of-type(2) label {
      font-weight: bold;
    }
  }
}

.sub_sector--multiselect {
  .multiselect__menu-list--is-multi {
    .multiselect__option:nth-of-type(2) label {
      font-weight: bold;
    }
  }
}

.sub_vertical--multiselect {
  .multiselect__menu-list--is-multi {
    .multiselect__option:nth-of-type(2) label {
      font-weight: bold;
    }
  }
}
