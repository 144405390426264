@import '../../../../../../../assets/scss/antd/variables';
// timeline
.ant-timeline {
  &.ant-timeline-label {
    .timeline-item--touch {
      padding-bottom: 0;

      .ant-timeline-item-label {
        width: 30%;
        top: 0;
        padding: 16px 23px 0 0;
      }

      .ant-timeline-item-tail,
      .ant-timeline-item-head {
        left: 30%;
      }

      .ant-timeline-item-tail {
        top: 0;
        height: 100%;
      }

      .ant-timeline-item-head {
        top: 25px;
        z-index: 1;
      }

      .ant-timeline-item-content {
        top: 0;
        margin: 0 0 0 2px;
      }

      &.ant-timeline-item-left {
        .ant-timeline-item-content {
          left: 30%;
          width: calc(70% - 2px);
        }
      }

      &.ant-timeline-item-last {
        .ant-timeline-item-tail {
          display: block;
        }
      }
    }

    // future touch
    .timeline-item--touch-future {
      .ant-timeline-item-content {

        > .ant-row {
          flex-wrap: nowrap;
        }
      }

      .ant-timeline-item-head {
        display: none;
      }

      &.bordered {
        border-bottom: 1px solid rgba(194, 207, 224, 0.5);
      }

      &.no-future {
        padding-bottom: 20px;
      }
    }

    .timeline-item--touch-no-future{
      .ant-timeline-item-content {
        padding: 16px 20px 12px 16px;
      }
    }
    @media (min-width: 1200px) and (max-width: 1600px) {
      .timeline-item--touch-no-future{
        .ant-timeline-item-content {
          min-height: 70px;
        }
      }
    }
    // collapse
    .ant-collapse-borderless {
      background: rgba(24, 144, 255, 0.05);
    }
  }
  .anticon-ellipsis{
    color:rgb(217, 217, 217);
    font-size:30px;
  }
  .ant-timeline-item:hover .anticon-up,
  .ant-timeline-item:hover .anticon-ellipsis,
  .ant-timeline-item .anticon-ellipsis.menu-active{
    color:#1890ff!important;
  }
  .timeline-item--touch .anticon-ellipsis{
    position: absolute;
    right:35px;
    top:16px;
  }
}

// collapse
.collapse-item--touch {
  > .ant-collapse-header {
    > .ant-row {
      flex-wrap: nowrap;
    }
  }
}

.ant-collapse.ant-collapse-borderless {
  > .collapse-item--touch {
    border: 0;
  }
}

.ant-collapse > .ant-collapse-item {
  &.collapse-item--touch {
    > .ant-collapse-header {
      padding-top: 16px;
      padding-right: 70px;

      .ant-collapse-arrow {
        top: 30px;
      }
    }
  }
}

.ant-collapse-item {
  &.collapse-item--touch {
    .ant-collapse-header {
      background: #fff;
    }

    &.ant-collapse-item-active {
      .ant-collapse-header {
        background: transparent;
      }
    }
  }
}

.touch-item {
  &__title {
    &.ant-typography {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      color: #334d6e;
    }
  }

  &__date {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    display: block;

    &.ant-typography {
      color: #616f82;
    }
  }

  &__attendees {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    &.ant-typography {
      color: #616f82;
    }

    &-operation {
      color: #87919f;
    }
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.1;
    color: #87919f;
    margin: 0 40px;
    img{
      max-width: 100%;
    }
    b{
      font-weight: 700;
    }
    .ql-size-small {
      font-size: 0.75em;
    }
    .ql-size-large {
      font-size: 1.5em;
    }
    .ql-size-huge {
      font-size: 2.5em;
    }
  }

  &__past-due {
    color: $red-6;
    padding-right: 10px;
    font-weight: bold;
  }
}

// timeline interval
.touch-timeline-interval {
  display: inline-flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: right;

  &__count {
    // blue-6
    color: #1890ff;
  }

  &__title {
    // Gray 10
    color: #87919f;
  }
}

// touches grid
.ant-card-grid {
  &.ant-card-grid--touches {
    padding: 0;
  }

  > .ant-timeline {
    // fix timeline hiding top border issue
    margin-top: 1px;
  }
}

.is-past-due {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    // red-6
    background: #f5222d;
    top: 2px;
    right: 2px;
  }
}
