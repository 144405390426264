.ant-picker-panel-container {
  display: flex;
  flex-direction: row-reverse;
  .ant-picker-footer {
    display: contents;
    .ant-picker-ranges {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-right: 1px solid #f0f0f0;
      padding: 40px 0 0 0;

      .ant-picker-preset {
        border-bottom: 1px solid #f0f0f0;
        padding: 5px;

        &:first-child {
          border-top: 1px solid #f0f0f0;
        }

        .ant-tag {
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          font-size: 14px;
          font-weight: 600;
          background: none;
          border: none;
        }
      }
    }
  }
}
