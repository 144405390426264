// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

// About media object pattern: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
// Examples: https://get.foundation/sites/docs/media-object.html

// Variables from global config.

/// Global value used for margin on components.
/// @type Number
$global-margin: 1rem !default;

/// Global value used for padding on components.
/// @type Number
$global-padding: 1rem !default;

/// Sets the text direction of the CSS. Can be either `ltr` or `rtl`.
/// @type Keyword
$global-text-direction: ltr !default;

// Internal variables used for text direction
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

$global-flexbox: true;
// END Variables from global config.

////
/// @group media-object
////

/// Bottom margin of a media object.
/// @type Number
$mediaobject-margin-bottom: $global-margin !default;

/// Left and right padding on sections within a media object.
/// @type Number
$mediaobject-section-padding: $global-padding !default;

/// Width of images within a media object, when the object is stacked vertically. Set to 'auto' to use the image's natural width.
/// @type Number
$mediaobject-image-width-stacked: 100% !default;

/// Adds styles for a media object container.
@mixin media-object-container {
  display: if($global-flexbox, flex, block);
  margin-bottom: $mediaobject-margin-bottom;

  @if $global-flexbox {
    flex-wrap: nowrap;
  }

  img {
    max-width: none;
  }

  //   @if $global-flexbox {
  //     &.stack-for-#{$-zf-zero-breakpoint} {
  //       @include breakpoint($-zf-zero-breakpoint only) {
  //         flex-wrap: wrap;
  //       }
  //     }
  //   }
}

/// Adds styles for sections within a media object.
/// @param {Number} $padding [$mediaobject-section-padding] - Padding between sections.
@mixin media-object-section($padding: $mediaobject-section-padding) {
  @if $global-flexbox {
    flex: 0 1 auto;
  } @else {
    display: table-cell;
    vertical-align: top;
  }

  &:first-child {
    padding-#{$global-right}: $padding;
  }

  &:last-child:not(:nth-child(2)) {
    padding-#{$global-left}: $padding;
  }

  > :last-child {
    margin-bottom: 0;
  }

  //   .stack-for-#{$-zf-zero-breakpoint} & {
  //     @include breakpoint($-zf-zero-breakpoint only) {
  //       @include media-object-stack;
  //     }
  //   }

  @if $global-flexbox {
    &.main-section {
      flex: 1 1 0px; // sass-lint:disable-line zero-unit
    }
  } @else {
    &.middle {
      vertical-align: middle;
    }

    &.bottom {
      vertical-align: bottom;
    }
  }
}

/// Adds styles to stack sections of a media object. Apply this to the section elements, not the container.
@mixin media-object-stack {
  padding: 0;
  padding-bottom: $mediaobject-section-padding;

  @if $global-flexbox {
    flex-basis: 100%;
    max-width: 100%;
  } @else {
    display: block;
  }

  img {
    width: $mediaobject-image-width-stacked;
  }
}

@mixin foundation-media-object {
  .media-object {
    @include media-object-container;
  }

  .media-object-section {
    @include media-object-section;
  }
}

@include foundation-media-object;
