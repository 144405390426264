.ant-menu-submenu {
  &.profile-submenu {
    > .ant-menu-submenu-title {
      height: 50px;
      display: flex;

      > .anticon {
        display: inline-flex;
        align-items: center;
        flex: 0 0 auto;
      }

      > span:not(.anticon) {
        max-width: calc(100% - 55px);
        display: flex;
        flex-direction: column;
      }
    }

    .profile-submenu__title {
      flex: 1;
      font-size: 16px;
      line-height: 24px;
      color: #afbdd1;
    }

    .profile-submenu__email {
      width: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #90a0b7;
      opacity: 0.5;
    }
  }
}

.ant-menu-inline-collapsed > .ant-menu-submenu {
  &.profile-submenu {
    > .ant-menu-submenu-title {
      .anticon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.ant-menu .notifications-anticon-wrapper{
  line-height: 1.125;
  padding-right:10px;
  margin-right:-10px;
}
