.nav-tabs {
  border-bottom: none;
  padding: 0 12px;
  .nav-item {
    .nav-link {
      padding: 12px;
      border: none;
      border-bottom: 2px solid #b1bece;
      font-size: 16px;
      color: #707f96;
      background-color: inherit;
      &.active {
        font-weight: 500;
        color: #000;
        border-bottom: 3px solid #1967ae;
      }
    }
  }
}
.tab-content {
  margin-top: 20px;
}
