// default value of 500 doesn't register as bold in windows browsers
// setting value to 600
.ant-popover-title span {
  font-weight: 600;
}

.popover-radio {
  .ant-radio-wrapper {
    display: block;
  }
}
