.ant-select {
  &--profile-descriptions {
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-color: transparent;
      padding: 0 11px 0 0;
    }

    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #40a9ff;
      border-right-width: 1px !important;
    }

    .ant-select-arrow {
      color: #1890ff;
    }
  }
}
