@import '../../antd/variables';

.pagination-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
}

.pagination-item {
  &:not(:last-child) {
    margin-right: 8px;
  }

  &.disabled {
    .pagination-link {
      color: $gray-6;
      cursor: not-allowed;
    }
  }

  &.active {
    .pagination-link {
      color: $blue-6;
      border-color: $blue-6;
    }
  }
}

.pagination-link {
  padding: 5px 13px;
  border: 1px solid $gray-5;
  box-sizing: border-box;
  border-radius: 4px;
  color: $gray-8;

  &:hover {
    color: $gray-8;
  }

  .anticon {
    vertical-align: middle;
  }
}
