.kpi {
  display: inline-flex;
  flex-direction: column;

  &__value {
    color: $kpi-value-color;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 2.14285;
    text-align: right;
  }

  &__label {
    color: $kpi-label-color;
    font-style: normal;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 2;
    text-align: right;
    text-transform: uppercase;
  }

  &--positive {
    .kpi__value {
      color: $green-6;
    }
  }

  &--negative {
    .kpi__value {
      color: $red-6;
    }
  }

  &--default {
    .kpi__value {
      color: $gray;
    }
  }

  & + & {
    margin: 0 0 0 30px;
  }
}
