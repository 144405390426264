.navigation-logo {
  position: relative;
  overflow: hidden;
  color: #ffffff;
  transition: all 0.2s ease;

  &__background {
    width: 100%;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
  }

  &__icon {
    margin: 0 8px 0 0;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }
}

.ant-layout-sider-collapsed {
  .navigation-logo {
    height: 58px;

    &__icon {
      margin: 0;
    }

    &__text {
      display: none;
    }
  }
}
