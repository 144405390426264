// override styles that break antd UI
.ant-message {
  .anticon {
    //   fix icon alignment
    svg {
      vertical-align: baseline;
    }
  }
}

.anticon {
  svg {
    vertical-align: baseline;
  }
}

// vertical align icon only buttons
.ant-btn-icon-only {
  &.ant-btn {
    &:focus > span,
    &:active > span {
      position: static;
    }
  }

  .anticon {
    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ant-btn-text {
  &.ant-btn-icon-only {
    vertical-align: top;
    &:hover,
    &:focus {
      color: #40a9ff;
    }
  }
}

.ant-select-item-option.sub-option {
  padding-left: 25px;
}
