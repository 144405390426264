@import '../../../../assets/scss/antd/variables';

.profile-navs {
  padding: 0;
  margin-top: 15px;

  li {
    &:not(:last-child) {
      margin-right: 35px;
      @media (max-width: 575px) {
        margin-right: 20px;
      }
      @media (max-width: 414px) {
        margin-right: 10px;
      }
    }
  }
  .nav-item {
    .nav-link {
      display: block;
      font-size: 16px;
      font-weight: 500;
      padding: 12px 6px;
      color: #707f96;
      border-bottom: none;
      &.active {
        color: #000;
        border-bottom: 3px solid #1aa7e8;
      }
      &:hover {
        color: #000;
        border-bottom: 3px solid #1aa7e8;
      }
    }
  }
}

.profile-content {
  min-height: calc(100vh - 63px);
}

.profile-card {
  border-radius: 8px;

  .ant-card-head {
    padding: 3px 40px;

    .ant-card-head-title {
      white-space: normal;
    }
  }

  .ant-card-body {
    padding: 40px;
  }

  &.card-company-sources {
    .ant-card-body {
      padding: 10px;
    }
  }

  &.card-funding-rounds {
    .funding-rounds-counter {
      text-align: right;

      .funding-rounds-counter__title {
        font-size: 10px;
        font-weight: 600;
        color: $blue-dark;
        text-transform: uppercase;
      }
      .funding-rounds-counter__value {
        color: $gray-icon;
        font-weight: 600;
      }
    }
  }
}

.list-table {
  width: 95%;
  margin: 0px auto;
  thead th {
    font-weight: bold;
  }
}

.notes-and-touches .primary-contacts-grid,
.add-to-equity-touch .primary-contacts-grid {
  & .ant-table-cell:nth-last-child(2) {
    border-right: none !important;
  }
  & .ant-table-cell.row_actions.sticky.sticky-right {
    border-left: 1px solid #f0f0f0;
  }
}

.ant-modal-body {
  .ant-form-item-control-input-content {
    span.validation-message {
      position: static;
    }
  }
}
