.single-select-menu-is-opened-container {
  .singleselect-select {
    &__control {
      border-radius: 0;
    }

    &__menu {
      position: static;
      margin-top: 0;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid hsl(0, 0%, 80%);
      border-top: 0;
    }

    &__single-value {
      margin-left: 25px;
    }

    &__value-container {
      > div:last-child {
        margin-left: 10px;
      }
    }
  }
}

.single-select-menu-is-opened-container.is-stage {
  .singleselect-select {
    &__menu {
      position: absolute;
    }
  }
}

.singleselect-wrapper {
  .singleselect-select__multi-value {
    display: none;
  }

  .singleselect-select__control {
    border: 1px solid #d9d9d9;
    border-radius: 0;

    &.singleselect-select__control--is-focused {
      border: 1px solid #d9d9d9;
    }

    .singleselect-select__value-container {
      padding-left: 34px;
      height: 40px;

      .multiselect-select__value-container__icon {
        position: absolute;
        top: 12px;
        left: 11px;
        height: 14px;
        > span {
          vertical-align: top;
        }
      }

      .singleselect-select__placeholder {
        color: #bfbfbf;
        font-size: 16px;
      }
    }

    .singleselect-select__indicator-separator {
      display: none;
    }

    .singleselect-select__indicator {
      color: #000000;
    }
  }

  .singleselect-select__menu {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    margin-top: 4px;
    margin-bottom: 4px;

    .singleselect-select__option {
      color: rgba(0, 0, 0, 0.85);
      &.singleselect-select__option--is-selected {
        background: none;
      }
    }
  }

  .singleselect-list__body {
    padding: 8px 0;
    .singleselect-list__body-tag {
      font-size: 12px;

      &.singleselect-list__body-tag__all {
        display: none;
      }

      .singleselect-select__multi-value__remove {
        display: inline-flex;
        padding: 0;

        &:hover {
          background: none;
          color: inherit;
        }

        .anticon {
          color: #1890ff;
          margin-left: 6px;
        }
      }
    }
  }
}
