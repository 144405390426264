@import '../../../assets/scss/antd/variables';
@import '../../../assets/scss/variables';
@import '../../../assets/scss/bootstrap/functions';
@import '../../../assets/scss/bootstrap/variables';
@import '../../../assets/scss/bootstrap/breakpoints';
@import '../../../assets/scss/layout/main-content';

$font-small: 11px;
$font-size-nomarl: 13px;

.profile-company {
  font-size: 14px;
  position: relative;
  ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
  }
  .card {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    padding: 24px;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding: 15px !important;
    }
  }

  .profile-inner {
    position: relative;

    @media only screen and (min-width: 960px) {
      padding: 0;
    }

    .profile-body {
      margin-top: -46px;

      @include mainContentSpacing();

      .ant-tabs-nav {
        &::before {
          border-bottom: 0;
        }

        .ant-tabs-ink-bar {
          background: $white;
        }

        .ant-tabs-tab {
          color: rgba(255, 255, 255, 0.5);
          text-transform: uppercase;
          letter-spacing: 1.25px;
        }

        .ant-tabs-tab-active .ant-tabs-tab-btn {
          color: $white;
        }
      }
    }

    .company-location {
      font-size: 14px;
      color: $gray;
      vertical-align: baseline;
      margin-top: 2px;
      display: flex;

      @media (max-width: 414px) {
        text-align: center;
      }

      svg {
        width: 14px;
        margin-right: 6px;
        vertical-align: baseline;
      }
    }

    .company-contact {
      .company-contact__header {
        margin-bottom: 24px;
      }
      .company-contact__title {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1px;
        color: #171725;
      }
      .company-contact__btn {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        color: #106cff;
        cursor: pointer;
        float: right;
        display: none;
      }
      ul.company-contact__main {
        padding: 0;
        li {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;
          .user-avatar {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            object-fit: contain;
            background-color: #01058a;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            text-align: center;
            padding: 10px 0px;
            float: left;
            &.null {
              background-color: grey;
            }
          }
          .user-info {
            display: flex;
            align-items: center;
          }
          .user-block {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 45px);
          }
          .contact-email,
          .contact-phone {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: #f6f7f9;

            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.null {
              pointer-events: none;
            }
          }
        }
      }

      .table {
        td:not(.rt-td--email):not(.rt-td--linkedin) {
          white-space: normal;
        }
      }
    }
    // End profile details

    .profile-sources {
      .source-title {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1px;
        color: #171725;
      }

      .source-collapse {
        .collapse-card-header button {
          padding: 25px 12px;
          border: none;
          font-size: 14px;
          color: #707f96;
          background-color: inherit;
          display: block;
          width: 100%;
          text-align: left;
          position: relative;
          font-weight: bold;

          .source-collapse-arrow {
            top: 33px;
            right: 11px;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-top-color: #b1bece;
            border-width: 7px;
            margin-left: -10px;
          }

          &:not(.collapsed) .source-collapse-arrow {
            border-top: none;
            border-bottom-color: #b1bece;
            bottom: 33px;
          }
        }
      }
    }
  }

  .company-location-row {
    max-width: calc(100% - 200px);
  }

  .social-media-cards {
    @media (min-width: 1200px) and (max-width: 1440px) {
      .ant-card-head-title {
        > .ant-row {
          flex-direction: column;
          > .ant-col {
            flex: 0 0 100%;
            max-width: 100%;
            .ant-row-end {
              justify-content: start;
            }
          }
        }
      }
    }
  }
}

.dropdownplus {
  width: 287px;
  .ant-input-affix-wrapper{
    height: 32px;
    max-width: 200px;
    margin-bottom: 24px;
    margin-right: 195px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .menu-list {
    height: 295px;
    overflow-y: scroll;
    border: 1px solid #f0f0f0;
    .list-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      label {
        width: 100%;
        padding: 10px 16px;
        margin: 0;
        display: flex;
        align-items: center;
      }
      p {
        display: inline-block;
        font-size: 15px;
        color: #007bfe;
        margin: 0;
        span.list-item__count {
          display: block;
          font-size: 14px;
          font-weight: normal;
          color: #c2c8cf;
        }
      }
    }
  }
  .menu-create {
    padding: 15px 24px;
    input {
      width: 100%;
    }
  }
}

.edit-note-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.contact-tooltip-content {
  .tooltip-inner {
    text-align: left;
  }
}

.profile-body {
  padding: 0px 0px;

  .ant-tabs-nav {
    margin: 0 0 40px;
  }

  .ant-tabs-nav-more svg {
    color: white;
  }

  .ant-tabs {
    overflow: visible;
  }
}

.profile-hero__owner_avatar {
  margin-top: 10px;
}
