.search-results {
  th.ant-table-cell {
    top: -1px;

    &.company_url,
    &.company_name,
    &.row_actions,
    &.score,
    &.score_v3 {
      z-index: 3;
    }
  }

  .ant-table-tbody {
    // individual cell styling
    .company_url {
      text-align: center;
    }

    .is_interesting {
      .psg-fit__thumbs-up {
        color: $green-6;
      }
    }

    .icon-globe,
    .anticon-ellipsis {
      color: #c2cfe0;
      &.not-active {
        color: red;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }

    .icon-pen {
      visibility: hidden;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }

    .anticon-ellipsis {
      transform: scale(1.7);
    }
  }
}

.dropdown-dot-list .anticon-ellipsis{
  transform: scale(1) !important;
}

// dropdown
.dropdown-add {
  .anticon {
    vertical-align: middle;
    color: $blue-6;
    margin-right: 9px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
  .edit-field--value,
  .profile-information__addon {
    display: block;
  }
}

.addon-popover {
  width: 450px;
}

// cell styling
.addon,
.company_owner,
.stage {
  .editable-cell__value {
    display: inline-block;
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.is_software,
.addon,
.company_owner,
.stage {
  .editable-cell-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.pipeline_rank {
  .editable-cell-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .not_editable {
    margin-right: 14px;
  }
}

.is_interesting {
  .icon-thumbs-up,
  .icon-thumbs-down {
    width: 16px;
    height: 16px;
  }

  .icon-pen {
    margin-left: 5px;
  }

  .editable-cell__value {
    display: inline-block;
  }

  .fit-icon {
    padding-left: 22px;
  }
}

.company_url {
  left: 0;
}

.is_interesting {
  text-align: center;
}

.row_actions {
  right: 0;
  text-align: center;

  &.ant-table-cell {
    z-index: 2;
  }
}

.company-name {
  display: inline-flex;

  &__profile-link {
    display: inline-block;
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.open-in-new {
  display: none;
}

.grid-page {
  .search-results {
    overflow: scroll;
  }

  &.fullscreen {
    .search-results {
      max-height: calc(100vh - 147px);
    }
  }

  &:not(.fullscreen) {
    .container-fluid {
      padding: 0 20px;
      max-width: 100%;
    }

    .search-results {
      overflow: auto;
      max-height: calc(100vh - 200px);
    }

    &.show-quick-filters {
      .search-results {
        max-height: calc(100vh - 474px);
      }
    }
  }
}

.wrap-controls {
  .grid-page {
    &:not(.fullscreen) {
      .search-results {
        max-height: calc(100vh - 218px);
      }

      &.show-quick-filters {
        .search-results {
          max-height: calc(100vh - 474px);
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .grid-page {
    &:not(.fullscreen) {
      &.show-quick-filters {
        .search-results {
          max-height: calc(100vh - 468px);
        }

        // Quick filters without histogram chart.
        &.show-quick-filters--small {
          .search-results {
            max-height: calc(100vh - 388px);
          }
        }
      }

      .search-results {
        max-height: calc(100vh - 225px);
      }
    }
  }
}

// fix for popover, when it's opened prevent click on neigbhouring cells,
// in order to close the popup, from performing other actions, such as
// redirecting to profile page
.popover-visible {
  .ant-table-cell {
    pointer-events: none;

    .dropdown-block {
      pointer-events: auto;
    }
  }
}

// Non-virtual grid changes
// This changes should be removed when cells are used only inside styled grid.
.legacy-table {
  // cell sizes
  .company_url {
    min-width: 42px;
    max-width: 42px;
  }

  .row_actions {
    min-width: 65px;
    max-width: 65px;
  }

  .pipeline_rank,
  .is_software {
    min-width: 85px;
    max-width: 85px;
  }

  .pipeline_rank {
    .not_editable {
      margin-right: 14px;
    }
  }

  .source_tag,
  .current_ceo,
  .ceo_approval,
  .g2_rating,
  .g2_review_count,
  .ct_rating,
  .ct_review_count,
  .year_founded,
  .job_openings {
    min-width: 90px;
    max-width: 90px;
  }

  .num_employees,
  .city,
  .country,
  .last_rev_update_amount,
  .sector,
  .sub_sector,
  .product_category,
  .sub_vertical {
    min-width: 100px;
    max-width: 100px;
  }

  .score,
  .score_v3,
  .stage,
  .job_openings_percent,
  .sw_website_rank_per_country,
  .raise_date,
  .last_round,
  .last_raised_amount,
  .capital_raised,
  .last_valuation,
  .last_touch_date,
  .next_touch_date,
  .cb_rank,
  .is_interesting {
    min-width: 110px;
    max-width: 110px;
  }

  .state {
    min-width: 120px;
    max-width: 120px;
  }

  .location,
  .sw_website_rank {
    min-width: 140px;
    max-width: 140px;
  }

  .emp_growth_percent {
    min-width: 150px;
    max-width: 150px;
  }

  .company_owner {
    min-width: 160px;
    max-width: 160px;
  }
  .diversity,
  .addon {
    min-width: 200px;
    max-width: 200px;
  }

  .company_name {
    min-width: 240px;
    max-width: 240px;
  }

  .next_touch_date {
    min-width: 120px;
    max-width: 120px;
  }

  .company_name {
    &.sticky {
      left: 42px;
    }
  }

  .score.sticky,
  .score_v3.sticky {
    left: 282px;
  }
}

.profile-information__edit {
  .edit-field--container {
    position: relative;
    padding-right: 30px;
    display: inline-block;
    max-width: 100%;
  }

  .edit-pen {
    position: absolute;
    top: 0;
    right: 0;
  }
}



.ebitda-value{
  width: 360px !important;

  .ant-input-affix-wrapper,
  input, .ant-row{
    max-width: 220px;
    width: 215px;
  }

  .error{
    width: 360px;
  }

  .profile-information__popover-buttons{
    padding-left: 227px;
  }
}

.negative-option--input {
  .ant-input {
    color: #f5222d;
  }
}