// OBS: in order for the content not to scroll add overflow: hidden on .ant-modal-body.
// If there is a sticky element inside this won't allow element to be sticky.
.ant-modal {
  &.ant-modal-full {
    top: 0;
    left: 0;
    // Set to default transform origin.
    transform-origin: 50% 50% 0 !important;
    padding: 0;
    max-width: none !important;
    width: auto !important;

    .ant-modal-body {
      min-height: 100vh;
    }
  }
}
