// override default colors. This rules use important rule to be sure the rule is applied.
.color-blue-6 {
  // blue-6
  color: #1890ff !important;
}

.color-cyan-6 {
  // cyan-6
  color: #13c2c2 !important;
}
