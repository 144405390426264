.prefixed-selection-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  &__prefix {
    color: #595959;
  }

  &__label {
    color: #90a0b7;
  }
}

.ant-select-item-option-content {
  .prefixed-selection-item__prefix {
    display: none;
  }

  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
}
