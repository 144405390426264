@import '../../../assets/scss/antd/variables';
@import '../../../assets/scss/variables';
@import '../../../assets/scss/bootstrap/functions';
@import '../../../assets/scss/bootstrap/variables';
@import '../../../assets/scss/bootstrap/breakpoints.scss';

.add-to-equity-touch {
  &__heading {
    background-color: #fff;
    padding: 0 28px;
    height: 64px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    .ant-form-item {
      margin: 0;
    }
  }

  &__title {
    color: #334d6e;

    &.ant-typography {
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  &__subtitle {
    font-size: 20px;
    color: $gray;
  }

  &__wrapper {
    padding: 0px 28px;
  }

  &__card {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
    margin-bottom: 30px;

    .ant-card-head-title {
      font-size: 20px;
      font-weight: 600;
      color: $blue-dark;
    }

    .ant-form-item-label {
      label {
        color: $gray-9;
      }
    }

    .ant-input,
    .ant-input-number-input,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-form-item textarea.ant-input {
      font-size: 16px;
      color: $blue-dark;
    }

    .ant-input,
    .ant-input-number-input,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
    }
    .ant-select-multiple .ant-select-selector {
      min-height: 40px;
    }

    .ant-form-item textarea.ant-input {
      min-height: 40px;
    }

    .ant-input-number {
      width: 100%;
    }

    .ant-select:not(.ant-select-multiple) {
      .ant-select-selector {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 38px;
        }
      }
    }

    .anticon-down {
      color: $blue-6;
    }

    .full_name {
      .ant-row {
        flex-flow: row nowrap;
      }
    }
  }

  // modal
  &__modal {
    .ant-table-wrapper {
      max-width: 520px;
    }

    th.ant-table-cell {
      padding: 15px 7px;
    }
  }

  // salesloft
  &__salesloft {
    .ant-select,
    .ant-select-multiple {
      width: 100%;
    }

    .ant-typography-danger {
      width: 200%;
    }

    .sl-cadence {
      .ant-select-selector {
        height: 40px;
        font-size: 16px;
        color: #334d6e;

        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          line-height: 38px;
        }
      }
    }
  }

  .salesloft-label {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  // confirmation
  .ant-result {
    padding: 90px 32px 164px;
    margin-top: 70px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
    border-radius: 8px;

    .anticon-check-circle svg {
      width: 63px;
      height: 63px;
    }
  }
}
