.search-input {
  position: relative;
  display: inline-block;
  border: 1px solid #f0f2f4;
  font-size: 14px;
  height: 40px;
  color: #364862;
  background: #f0f0f5;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  width: 220px;
  margin-right: 8px;
  float: left;

  input {
    color: rgb(73, 80, 87);
    height: 40px;
    padding-left: 40px;
    width: 100%;
  }

  .btn-search {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 40px;
  }

  .icon-search {
    margin: auto;
    font-size: 18px;
    color: #707f96;
  }
}

.search-tooltip {
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: #0061c1;
  align-self: flex-end;
  margin: 0 0 8px 7px;
}

.search-hints-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
