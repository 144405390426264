.admin-page {
  .rt-th--row-actions,
  .rt-td--row-actions {
    max-width: 65px;
  }

  .rt-td--row-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon-ellipsis {
      transform: scale(2);
    }
  }
}
