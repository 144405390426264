.trending-value {
  &--up {
    // green-6
    color: #52c41a;
    font-size: 12px;
  }

  &--down {
    // red-6
    color: #f5222d;
  }
}
