.home-page {
  .popover-header {
    background-color: #fff;
    color: rgb(97, 104, 111);
  }

  .btn-group-chart {
    padding: 0;
    .btn-group {
      padding-bottom: 50px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .btn-previous {
    padding-right: 10px;
  }

  .icon-previous {
    padding: 0px 5px;
  }
  .icon-newer {
    padding-left: 5px;
  }

  .pagination-group-page {
    float: left;
    padding-top: 10px;
    margin-bottom: 0px;
  }

  .pagination-group-size {
    float: right;
    margin-bottom: 5px;

    .size-page {
      padding: 10px 2px 0px 0px;
    }
  }
}

.home-page {
  .card {
    background: none;
    box-shadow: none;
    display: table;
  }

  .card-body {
    padding: 0 10px 0 0;
  }

  .table-responsive {
    display: table;
    background: #fff;
    padding: 10px 10px 30px;
    border-radius: 4px 4px 0 0;
  }
}

.fixed-message__container.ant-message {
  position: relative;
  top: 0;
  z-index: 0;
  margin-bottom: 20px;

  .ant-message-notice {
    text-align: left;
    padding: 0;

    .ant-message-notice-content {
      padding: 10px;

      .anticon-close-circle {
        vertical-align: text-bottom;
      }

      button {
        padding: 4px 10px;
        font-weight: bold;
      }
    }
  }
}

.quick-filters-group {
  padding: 20px 40px;
  background-color: rgb(240, 242, 245);
  .filter-form__slider-input,
  .filter-form__extra-content {
    display: none;
  }

  .filter-form__card {
    width: 100%;
    max-width: 330px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    margin-bottom: 0;

    .ant-card-body {
      padding: 15px;

      .filter-form__card-title {
        font-size: 16px;
        color: #000;
        font-style: normal;
        font-weight: normal;
      }

      .ant-slider {
        margin: 10px 7px;
      }
    }
  }

  // Styled to fill card height.
  .filter-form__card-wrapper {
    display: flex;
    margin: 8px;
    min-height: 160px;
    min-width: 330px;
  }

  .ant-card-body,
  .ant-card-meta {
    height: 100%;
  }
  .ant-card-meta {
    margin: 0;
  }

  .ant-card-meta-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .ant-card-meta-title {
      margin-bottom: 0;
    }
  }
}

// Transition animation for quick filters.
.quick-filters-transition-enter {
  opacity: 0;
  max-height: 0;
}
.quick-filters-transition-enter-active {
  opacity: 1;
  transition: max-height 300ms;
  max-height: 400px;
}
.quick-filters-transition-exit {
  opacity: 1;
  max-height: 400px;
}
.quick-filters-transition-exit-active {
  opacity: 0;
  max-height: 0;
  transition: max-height 300ms;
}

@media only screen and (max-width: 1024px) {
  .home-page {
    .btn-previous {
      padding-right: 5px;
    }
    .btn-group-chart {
      .btn-group {
        padding: 0px 10px 5px 0px;
        .btn-drd {
          font-size: 10px;
        }
        width: 70px;
      }
    }
  }
  .rcw-widget-container.rcw-opened {
    margin: 0;
    max-width: 80%;
    width: 80%;
    height: 60%;
  }
}
.rcw-title {
  display: none;
}
