// libraries
@import '~sanitize.css';
@import '../scss/bootstrap/functions.scss';
@import '../scss/bootstrap/variables.scss';
@import '../scss/bootstrap/breakpoints.scss';
@import '../scss/bootstrap/_rfs.scss';
@import '../scss/bootstrap/_hover.scss';
@import '../scss/bootstrap/_reboot.scss';
@import '../scss/bootstrap/_borders.scss';
@import '../scss/bootstrap/_flex.scss';
@import '../scss/bootstrap/_spacing.scss';
@import '~react-datepicker/src/stylesheets/datepicker';
@import '~react-chat-widget/lib/styles.css';
// local styles
@import 'settings/variable';
@import 'settings/custom-tabs';
@import 'settings/custom-dropdown';
@import 'settings/custom-modal';
@import 'settings/custom-pagination';
@import '../scss/style.scss';
// antd
@import '../scss/antd/index.scss';

.Toastify__toast--error svg {
  fill: var(--toastify-icon-color-error);
}

.Toastify__toast--warning svg {
  fill: var(--toastify-icon-color-warning);
}

.Toastify__toast--success svg {
  fill: var(--toastify-icon-color-success);
}

body.chrome-extension-body .Toastify__toast-container {
  left: 26.5%;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: inherit;
  background-color: inherit;
}

.container-fluid {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10px;
  @media only screen and (min-width: 1800px) {
    max-width: 95%;
  }
  @media only screen and (min-width: 1440px) {
    max-width: 95%;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 100%;
  }
}
.rcw-widget-container {
  z-index: 1031 !important;
}
@media only screen and (max-width: 768px) {
  .rcw-widget-container.rcw-opened {
    height: 50% !important;
    bottom: 45%;
  }
}

header .filter .dropdown-menu.date-range-dropdown {
  overflow: visible;
  height: auto;

  .react-datepicker-wrapper input {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.hidden {
  display: none;
}
.title-company {
  color: rgb(73, 80, 87);
}
.column-header-box {
  label input {
    margin-right: 5px;
  }
  max-height: 300px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch !important;
}

.empty-chart {
  .recharts-area {
    display: none;
  }

  .recharts-text tspan {
    fill: #707f96;
  }
}

.growth-up {
  color: #00b4a3 !important;
  &:after {
    content: '↗';
    font-size: 12px;
    margin-left: 6px;
  }
}
.growth-down {
  color: #d6005c !important;
  &:after {
    content: '↘';
    font-size: 12px;
    margin-left: 6px;
  }
}

.message-error {
  display: block;
  width: 100%;
  font-size: 18px;
  color: red;
  text-align: center;
  padding: 20px;
}

.row-spacer {
  padding-bottom: 20px;
}

.keep-space-sequence {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
