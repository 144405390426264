@import '../../../assets/scss/antd/variables';

.add-research-rationale {
  &.plugin {
    width: 640px;

    .content-title {
      padding-top: 15px;
    }

    #score-card {
      .ant-card-body {
        background: #f5f7fa;
      }
    }
  }

  .ant-drawer-content-wrapper {
    min-width: 550px;
  }

  .ant-drawer-header {
    box-shadow: 5.20193px 0px 15.6058px rgba(0, 0, 0, 0.06);
    padding: 21px 21px 22px 29px;

    .company-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 22px 0;

      button {
        margin-left: 10px;
      }
    }

    .ant-drawer-title {
      font-weight: normal;
    }
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    min-height: 130px;
  }

  .ant-drawer-body {
    padding: 0 32px 0 30px;
    background-color: white;

    #score-card {
      .ant-card-body {
        background: #f5f7fa;
      }
    }
  }
}

.card-edit-software-company {
  width: 100%;
  border-radius: 8px;
}

.card-edit-psg-fit {
  width: 100%;
  border-radius: 8px;
}

.rationale-radio-group {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#score-card {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
}

.rationale-other {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  .rationale_select-async {
    width: 250px;
  }
}
