// 1. bootrap overrides
.btn {
  // padding: rem-calc(8 23);

  &-primary {
    background-color: #1967ae;
    border: 1px solid #1967ae;
    // box-shadow: 0 1px 1px 0 rgba(13, 56, 95, 0.54);
  }

  // outlined button
  &-outline {
    color: #707f96;
    border: 1px solid currentColor;

    &:hover {
      background: lighten(#707f96, 40%);
    }

    &.btn-primary {
      color: #1967ae;
      background-color: inherit;

      &:hover {
        background-color: lighten(#1967ae, 50%);

        &:disabled {
          background-color: inherit;
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .btn {
    font-size: 13px;
    padding: 0.4375rem 0.75rem;
  }
}
