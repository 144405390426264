.my-companies-card {
  height: 100%;

  &.active {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);

    .ant-card-head {
      background: linear-gradient(111.6deg, #40a9ff -93.14%, rgba(255, 255, 255, 0) 96.73%), #096dd9;

      .ant-card-head-title .my-companies-card__title {
        &-icon {
          color: #91d5ff;
        }
        &-text {
          color: #fff;

          span {
            color: #fff;
          }
        }
        &-total span {
          color: #91d5ff;
        }
      }
    }
  }

  .ant-card-head {
    padding: 0 22px;

    .ant-card-head-title {
      padding: 30px 0;

      .my-companies-card__title {
        text-align: center;

        &-icon {
          color: #1890ff;
          font-size: 30px;
        }
        &-text {
          color: #334d6e;

          span {
            color: #334d6e;
            font-size: 16px;
          }
        }
        &-total span {
          font-size: 14px;
          color: #1890ff;
        }
      }
    }
  }

  .ant-card-body {
    padding-top: 25px;
    padding-bottom: 50px;

    .my-companies-card__content {
      &-description {
        font-size: 14px;
        color: #c2cfe0;
        font-weight: 400;
        margin-bottom: 16px;
      }

      .kpi {
        border: 1px solid transparent;
        padding: 3px;
        &__value,
        &__label {
          text-align: left;
        }

        &.active {
          background-color: #d1e9ff;
        }
        &:hover {
          border: 1px solid #d1e9ff;
          .kpi__value,
          .kpi__label {
            color: #1890ff !important;
          }
        }
      }

      &-barchart {
        svg > g:last-child {
          z-index: 0;
        }
      }

      .my-companies-card-columns__content {
        .my-companies-card-columns__content-column {
          &:not(:nth-child(1)):not(:nth-child(2)) {
            padding-top: 10px;
          }
        }
      }
    }
  }
}
