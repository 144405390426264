@import '../../assets/scss/variables';

// Import .CSV modal
.dropdownimport {
  width: 295px;

  .ant-popover-title {
    padding: 10px 24px;
    text-align: center;
  }

  .ant-popover-inner-content {
    padding: 0;

    .dropdownimport__info .ant-alert-message {
      font-size: 12px;
      color: $info;
    }

    .dropdownimport__choosefile {
      padding: 15px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    .dropdownimport__input {
      padding: 15px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    .dropdownimport__button {
      padding: 15px 24px;

      button {
        width: 100%;
      }
    }
  }
}

.dropdown-input {
  .dropdown > button.right {
    top: 0;
    left: 25px;
  }
  .menu-header {
    padding: 10px 24px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #364862;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .menu-item {
    padding: 15px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    input {
      display: block;
      width: 100%;
    }
  }
  .menu-input {
    input {
      height: 40px;
      font-size: 15px;
      font-weight: 500;
      color: #707f96;
      padding-bottom: 10px;
    }
  }
  .menu-choose-file {
    padding: 15px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .menu-footer {
    padding: 15px 24px;
    button,
    a {
      display: inline-block;
      width: 100%;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}

.dropdown-input__rename-content {
  background-color: #fff;

  .menu-input input {
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.65);
    padding-bottom: 4px;
    border-bottom: 1px solid rgb(217, 217, 217);
  }
}

.box {
  width: 100%;
  height: 110px;
  position: relative;
  text-align: center;
  border: 2px dashed #c2c8cf;
  color: #364862;

  .text-danger {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 8px;
    text-align: center;
  }

  .box__overlayer {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    > * {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      text-align: center;
      color: grey;
      font-size: 16px;
    }
  }
  .file-name {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 5px 10px;
  }
  svg {
    width: 40px;
    fill: #c2c8cf;
    display: block;
    margin: 15px auto;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
  .box__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    cursor: pointer;
  }
}

.dropdown-dot-remove {
  position: absolute;
  top: 16px;
  right: 16px;
  i {
    font-size: 14px;
    color: #c2c8cf;
  }
  .ant-btn {
    background: #fff;
    border: none;
    padding: 1px 10px;
  }
}

.dropdown-math {
  display: inline-block;
  .dropdown {
    position: static;
    display: inline-block;
    width: 40px;
    .btn {
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      padding: 0;
      margin: 0;
      background-color: #fff;
      border-radius: 0;
      color: #707f96;
    }
    .dropdown-menu {
      min-width: 40px;
      position: static !important;
      transform: translate3d(0, -2px, 0) !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      margin: 0;
      button {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        padding: 0;
      }
    }
  }
}
