.ant-card--touches {
  .ant-card-head-wrapper {
    flex-wrap: wrap;
  }

  .ant-card-head-title {
    overflow: auto;
    flex: 1 1 auto;
    margin-right: 8px;
  }
}
