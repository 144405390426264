@import '../../../assets/scss/antd/variables';
@import '../../../assets/scss/variables';

.company-list {
  background: $bg-body;
  min-height: calc(100vh - 63px);
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  color: $gray-8;

  .company-list__header {
    background: $white;
    padding: 10px 40px 0;
  }

  .ant-tabs {
    .ant-tabs-bar,
    .ant-tabs-nav {
      background: $white;
      padding: 10px 40px 0;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-tabpane {
      padding: 24px 40px 64px;
    }

    .source-scrub-lists {
      padding-top: 0;
    }
  }
  .ant-tabs-content {
    position: static;
  }

  .tab-content {
    margin: 33px 0 0;
  }

  .rt-td {
    &--title {
      font-size: 16px;
      line-height: 24px;
      color: #696974;
    }
  }

  .has-search {
    position: relative;
    padding-top: 64px;
  }

  .main-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .list-tab {
    .legacy-table {
      overflow: auto;
      max-height: calc(100vh - 263px);
    }
  }
}

.search-results.source-scrub-grid {
  .anticon-ellipsis {
    transform: none;
  }
}

.grid-page {
  &:not(.fullscreen) {
    .source-scrub-grid {
      max-height: calc(100vh - 242px);
    }
  }

  &.fullscreen.has-search {
    .source-scrub-grid {
      overflow: scroll;
      max-height: calc(100vh - 144px);
    }

    .company-list__header,
    .ant-tabs-nav {
      display: none;
    }

    th.ant-table-cell {
      top: -1px;
    }
  }
}

.company-list,
.company-list__popover {
  .saved {
    &__filter {
      display: inline-block;
      margin: 0 10px 0 0;
      padding: 0 5px;
      background: #f5f5f5;
      border: 1px solid #dfdfdf;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__filter-name {
      text-transform: uppercase;
    }

    &__filter-value {
      color: #4180d6;
    }
  }
}

.company-list__popover {
  .saved {
    &__filter {
      margin: 0 10px 10px 0;
    }
  }
}

.dropdown-dot {
  .dropdown-dot__icon {
    color: #c2cfe0;
    font-size: 28px;
  }
}

@media only screen and (max-width: 414px) {
  .company-list {
    .company-list__body {
      .tab-content {
        margin-top: 70px;
      }
    }
  }
}
