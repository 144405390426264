/* ==============
  General
===================*/

body {
  background: $bg-body;
  margin: 0;
  font-size: $base-font;
  // There is some library that is adding overflow: visible; to body by default.
  overflow-x: hidden !important;
}
.body {
  height: auto;
}
html {
  position: relative;
  min-height: 100%;
  background: $bg-body;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
}

h1 {
  line-height: 43px;
}

h2 {
  line-height: 35px;
}

h3 {
  line-height: 30px;
  small {
    color: $lightdark4;
  }
}

h4 {
  line-height: 22px;
  small {
    color: $lightdark4;
  }
}

h5 {
  small {
    color: $lightdark4;
  }
}

b {
  font-weight: 500;
}

* {
  outline: none !important;
}

a {
  &:hover {
    outline: 0;
    text-decoration: none;
  }
  &:active {
    outline: 0;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

code {
  color: $info;
}

.page {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.btn-link:hover,
.btn-link:focus {
  text-decoration: none !important;
}
