// generic styles for svg icons
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-bookmark {
  path {
    fill: transparent;
  }
}
