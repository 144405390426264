.multiselect-wrapper {
  .multiselect-select__multi-value {
    display: none;
  }

  .multiselect-select__control {
    border: 1px solid #d9d9d9;
    border-radius: 0;

    &.multiselect-select__control--is-focused {
      border: 1px solid #d9d9d9;
    }

    .multiselect-select__value-container {
      padding-left: 34px;
      height: 40px;

      .multiselect-select__value-container__icon {
        position: absolute;
        top: 12px;
        left: 11px;
        height: 14px;
        > span {
          vertical-align: top;
        }
      }

      .multiselect-select__placeholder {
        white-space: nowrap;
        overflow: hidden;
        color: #bfbfbf;
        font-size: 16px;
      }
    }

    .multiselect-select__indicator-separator {
      display: none;
    }

    .multiselect-select__indicator {
      color: #000000;
    }
  }

  .multiselect-select__menu {
    z-index: 2;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    margin-top: 4px;
    margin-bottom: 4px;

    .multiselect-select__option {
      &:hover {
        background-color: #deebff;
      }

      &--is-disabled {
        &:hover {
          background-color: inherit;
        }
      }

      &.multiselect-select__option--is-selected {
        background: none;
      }

      &.multiselect-select__option--none {
        border-bottom: 1px solid #d9d9d9;
        padding: 15px 12px 17px;
        margin-bottom: 8px;
      }

      &--is-focused {
        background-color: inherit;
      }
    }
  }

  .multiselect-list__body {
    padding: 8px 0;

    .multiselect-list__body-tag {
      font-size: 12px;
      cursor: pointer;

      &.multiselect-list__body-tag__all {
        display: none;
      }

      &.multiselect-list__body-tag__none {
        .anticon {
          display: none;
        }
      }

      .multiselect-select__multi-value__remove {
        display: inline-flex;
        padding: 0;
        // same vertical align as .anticon
        vertical-align: -0.125em;

        &:hover {
          background: none;
          color: inherit;
        }

        .anticon {
          color: #1890ff;
          margin-left: 6px;
        }
      }
    }
  }
}

.async-create-select {
  width: 100%;

  .async-create-select__multi-value {
    display: none;
  }

  .async-create-select__control {
    border: 1px solid #d9d9d9;
    border-radius: 0;

    &.async-create-select__control--is-focused {
      border: 1px solid #d9d9d9;
    }

    .async-create-select__value-container {
      height: 40px;

      .async-create-select__value-container__icon {
        position: absolute;
        top: 12px;
        left: 11px;
        height: 14px;
        > span {
          vertical-align: top;
        }
      }

      .async-create-select__placeholder {
        white-space: nowrap;
        overflow: hidden;
        color: #bfbfbf;
        font-size: 16px;
      }
    }

    .async-create-select__indicator-separator {
      display: none;
    }

    .async-create-select__indicator {
      color: #000000;
    }
  }

  .async-create-select__menu {
    z-index: 2;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    margin-top: 4px;
    margin-bottom: 4px;

    .async-create-select__option {
      &:hover {
        background-color: #deebff;
      }

      &--is-disabled {
        &:hover {
          background-color: inherit;
        }
      }

      &.async-create-select__option--is-selected {
        background: none;
      }

      &.async-create-select__option--none {
        border-bottom: 1px solid #d9d9d9;
        padding: 15px 12px 17px;
        margin-bottom: 8px;
      }

      &--is-focused {
        background-color: inherit;
      }
    }
  }

  .multiselect-list__body {
    padding: 8px 0;

    .multiselect-list__body-tag {
      font-size: 12px;
      cursor: pointer;

      &.multiselect-list__body-tag__all {
        display: none;
      }

      &.multiselect-list__body-tag__none {
        .anticon {
          display: none;
        }
      }

      .async-create-select__multi-value__remove {
        display: inline-flex;
        padding: 0;
        // same vertical align as .anticon
        vertical-align: -0.125em;

        &:hover {
          background: none;
          color: inherit;
        }

        .anticon {
          color: #1890ff;
          margin-left: 6px;
        }
      }
    }
  }
}
