@import '../../../../../../assets/scss/antd/variables';
@import '../../../../../../assets/scss/bootstrap/functions';
@import '../../../../../../assets/scss/bootstrap/variables';
@import '../../../../../../assets/scss/bootstrap/breakpoints';

.ant-tooltip-custom {
  .ant-tooltip-inner {
    padding: 6px;
  }
}

.profile-box {
  box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
  border-radius: 8px;
  margin-bottom: 20px;

  .ant-card-head-title {
    font-size: 20px;
    line-height: 28px;
  }
}

.truncate-popover {
  .ant-popover-content {
    max-width: 250px;
    word-break: break-word;
  }
}

.profile-information,
.profile-graphs {
  width: 100%;
}

.profile-information {
  font-weight: 600;

  &__section-title {
    padding: 0 0 8px;
    font-size: 16px;
    line-height: 24px;
    height: initial !important;
    color: $gray-9;

    &:not(.first-child) {
      padding-top: 20px;
    }
  }

  &__sub-vertical {
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      width: 100%;
      max-width: fit-content;
    }
  }

  .ant-card-body {
  & > .ant-row {
    height: 25px;
  }
}

  &__card {
    height: 100%;
  }

  &__label {
    width: 39%;
    padding-right: 4%;
    line-height: 22px;
    color: $gray;
  }

  &__value {
    color: #374961;
    width: 61%;
  }

  &__edit {
    width: 38%;
    color: $blue-6;

    &:hover {
      cursor: pointer;
      .edit-pen {
        visibility: visible;
      }
    }
  }

  .proprietary-icon {
    .anticon {
      vertical-align: middle;
      margin-left: 7px;
      width: 21px;
      height: 21px;

      svg {
        position: relative;
        top: -2px;
        width: 100%;
        height: 100%;
      }
    }
  }

  .edit-pen {
    padding-left: 10px;
    visibility: hidden;
  }

  .icon-pen {
    width: 18px;
    height: 18px;
  }

  &__date-presented {
    .ant-picker {
      margin-top: 10px;
      width: 277px;
    }
  }

  &__popover-buttons {
    margin: 22px 0 0 -16px;
    width: calc(100% + 32px);
    padding-top: 10px;
    border-top: 1px solid $gray-5;
    text-align: right;
  }

  &__cancel {
    margin-right: 8px;
  }

  &__save {
    margin-right: 16px;
  }

  &__addon {
    display: flex;
    align-items: flex-start;
  }

  &__financial {
    .ant-select {
      width: 100%;
    }

    .ant-picker {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

.profile-chart {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);

  &.funding-chart {
    border-radius: 4px 4px 0 0;
  }

  &.score-chart {
    border-width: 0 1px;
  }

  &.employee-chart {
    border-radius: 0 0 4px 4px;
  }
}

@include media-breakpoint-up(xl) {
  .profile-information {
    &__label {
      width: 58%;
    }

    &__value {
      width: 42%;
    }
  }
}
.sub-vertical,
.product-category {
  width: 95%;
}
