.filter-form__card-group {
  .ant-card-meta-description {
    > .ant-card {
      margin-bottom: 20px !important;
      box-shadow: none;
      border: none;
      .ant-card-body {
        padding: 0;
        .ant-card-meta-title {
          font-weight: normal;
        }

        .ant-form-item {
          margin-bottom: 10px;
        }
      }
    }
  }
}
