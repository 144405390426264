.competitor-card {
  &__name {
    display: inline-block;
    font-weight: 600;
    /* Gray / gray-8 */
    color: #595959;
  }

  &__link {
    /* Gray / gray-6 */
    color: #bfbfbf;
  }

  .score-trending-wrapper {
    width: 110px;
    text-align: center;

    .score-trending {
      &__label {
        font-size: 10px;
      }

      &__growth-score {
        font-size: 18px;
      }

      .trending-value {
        font-size: 16px;
      }
    }

    .is-psg-company {
      color: rgb(30, 58, 81);
      font: bold 32px 'Times New Roman';
    }
  }
}
