@import '../../../assets/scss/antd/variables';
@import '../../../assets/scss/variables';
@import '../../../assets/scss/bootstrap/functions';
@import '../../../assets/scss/bootstrap/variables';
@import '../../../assets/scss/bootstrap/breakpoints.scss';

.add-to-salesloft {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  min-height: 100vh;

  &__header {
    background-color: #fff;
    height: 64px;
    padding: 0 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__wrapper {
    padding: 0 40px;
    .ant-card-head {
      font-size: 20px;
      font-weight: 600;
      color: #334d6e;
    }

    .title-button__wrapper {
      text-align: right;
    }
  }

  &__header-buttons {
    .ant-btn {
      margin-left: 12px;
    }
  }

  &__title {
    &.ant-typography {
      margin-bottom: 0;
      color: #334d6e;
      font-weight: 600;
    }
  }

  &__subtitle {
    display: inline-block;
    font-size: 20px;
    color: $gray;
  }

  &__submit-btn {
    position: absolute;
    top: 100px;
    right: 0;
  }

  &__confirmation {
    background: #fff;
    box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
    padding: 108px 32px;
  }

  .cadence-card {
    height: 100%;
    margin-top: 30px;

    .ant-select {
      width: 350px;
    }

    .ant-card-body {
      padding: 24px;
    }
  }

  .blue-outline:not(:disabled) {
    color: $blue-6;
    border-color: $blue-6;
  }

  .primary-contacts-grid {
    table-layout: fixed;
    overflow: auto;
    margin-top: 14px;

    & .ant-table-cell:nth-last-child(2) {
      border-right: none !important;
    }
    & .ant-table-cell.row_actions.sticky.sticky-right {
      border-left: 1px solid #f0f0f0;
    }
  }

  .anticon-linkedin {
    color: $blue-dark;

    &.disabled {
      cursor: not-allowed;
      color: $red-6;
    }
  }

  .icons {
    min-width: 125px;
    max-width: 125px;

    .ant-col {
      display: flex;
      padding: 2px 0;
    }

    .anticon {
      margin-right: 19px;
    }
  }

  .icon-salesloft {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: -48px;
  }

  .salesloft-label {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .ant-table-selection-column {
    .ant-radio-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

@include media-breakpoint-up(xl) {
  .add-to-salesloft {
    .cadence-card {
      margin-top: 0;

      .ant-card-body {
        padding: 24px;
      }
    }
  }
}
