.company-source-descriptions {
  &__text {
    color: #90a0b7;
    margin: 5px 0 0;
  }


  &__no-selection{
    margin-right: 12px;
  }
  .prefixed-selection-item {
    font-size: 14px;
    font-weight: 600;

    &__label {
      color: #096dd9;
    }
  }

  .powered-by-ai-indicator {

    .powered-by-ai-tag {
      margin-left: 4px;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
