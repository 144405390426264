.filter-histogram {
  width: 100%;
  height: 80px;
  box-sizing: border-box;

  &.histogram-hidden {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  .filter-histogram__tooltip {
    background-color: rgba(0, 0, 0, 0.75);
    padding: 6px 8px;
    color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
