.ant-modal {
  &.ant-modal--filters {
    .ant-modal-content {
      background: #f5f7fa;
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-title {
    font-weight: bold;
  }
}
