$color-blue: #2ca0f7;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #b8bfcb;

$color-disabled: #dfe3e9;
//background

$color_bg_white: #fff;
$color-background: #e8e8e8;
