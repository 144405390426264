@import '../../../../assets/scss/antd/variables';
@import '../../../../assets/scss/variables';

.profile-hero {
  &.is-fullscreen {
    display: none;
  }

  .profile-navigation {
    white-space: nowrap;
    margin-top: -5px;
    .ant-btn-link {
      color: #b0d2f0;
    }
    .ant-btn-link:hover:not([disabled]) {
      color: #ffffff;
    }
  }
  .profile-breadcrumbs {
    flex-flow: row nowrap;

    &--wrapper {
      overflow: hidden;
    }

    &--column {
      display: flex;
      align-items: center;
    }
  }

  .company-rank {
    white-space: nowrap;

    .btn-start {
      cursor: pointer;
      vertical-align: middle;

      .anticon {
        font-size: 20px;

        svg path {
          fill: #ffdb71;
        }

        &.unfavorited svg path {
          fill: $light5;
        }
      }
    }

    .rank {
      display: inline-block;
      margin-left: 10px;
      color: $light5;

      span {
        margin-left: 20px;
        color: $light5;
      }
    }

    .dropdown-toggle {
      width: 130px;
      height: 32px;
      object-fit: contain;
      border-radius: 4px;
      box-shadow: 0 2px 2px 0 rgba(6, 21, 33, 0.03), 0 0 0 1px rgba(16, 25, 32, 0.06);
      background-color: #ffffff;

      font-size: 14px;
      color: #000000;
      position: relative;
      &::after {
        position: absolute;
        right: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        border-top: 0.3em solid #707f96;
      }
    }

    label {
      font-size: 14px;
      color: #707f96;
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  .company_review {
    white-space: nowrap;
    margin-top: -5px;

    &__cloud {
      color: $light5;
      width: max-content;
      &__label {
        margin-left: 10px;
        margin-right: 8px;
        color: $light5;
      }

      .anticon-cloud {
        margin-top: -5px;
      }
    }

    .ant-btn-default {
      color: #b0d2f0;
      margin-right: 8px;
    }
    .ant-btn-default:hover:not([disabled]) {
      color: #ffffff;
    }
  }
}

.profilelinks {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.company-links-col {
  flex: 1 0 0;

  .profile-hero__owner_avatar {
    position: absolute;
    top: 65px;
    right: 0;
  }
}

.profile-edit-actions {
  margin: 0 0 5px 10px;

  .editallbutton,
  .notesbutton {
    margin-right: 10px;
  }

  .profiledotsbutton {
    font-size: 32px;
    color: $blue-6;
    cursor: pointer;
  }

  .dropdown-tags {
    display: inline;
  }
}

.socialmedia,
.profile-edit-actions {
  float: right;
}

.add-tags-suffix {
  position: relative;

  .fit-default,
  .edit-field--container {
    position: absolute;
    left: 100%;
    width: 170px;
  }

  .edit-field--container {
    top: 0;
  }

  .fit-default {
    top: -14px;
  }

  .psg-fit-wrapper {
    .fit-default,
    .edit-field--value {
      justify-content: left;
    }
  }
}

.socialmedia {
  margin-top: 3px;

  ul {
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      padding-right: 10px;
    }

    li {
      padding: 2px 0 2px 10px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    li:has(a > svg.icon-bvdorbis) {
      padding-top: 0;
      padding-bottom: 0;
    }

    svg {
      color: #0050b3;
    }

    svg:not(.icon-bvdorbis) {
      width: 20px;
      height: 20px;

      path,
      rect {
        fill: inherit;
        color: #0050b3;
        fill-opacity: 1;
      }
    }

    svg.icon-bvdorbis {
      width: 22px;
      height: 22px;
    }
  }
}

.profile__parent-company {
  margin: 0px 0px 5px;
  span {
    margin-right: 0;
    &:hover {
      cursor: pointer;
    }
  }
}

.dropdownprofile__menu {
  .anticon {
    vertical-align: middle;
    color: #1890ff;
    margin-right: 9px;

    svg {
      color: #1890ff;
      width: 14px;
      height: 14px;

      path,
      rect {
        fill: #1890ff;
        color: #1890ff;
        fill-opacity: 1;
      }
    }
  }
  .edit-field--value,
  .profile-information__addon {
    display: block;
  }
}

.owner-fit-wrapper {
  .edit-field--value {
    display: flex;
  }

  .fit-default,
  .edit-field--value {
    justify-content: right;
  }

  .ant-btn--is-fit {
    padding-right: 0;
  }
}
