// .ant-table-wrapper
.legacy-table {
  // general cell styling
  .ant-table {
    &.ant-table-bordered {
      thead,
      tbody {
        & > tr {
          .sticky-left {
            border-right: 2px solid #badeff;
          }
          .sticky-right {
            border-left: 2px solid #badeff;
          }
        }
      }
    }

    // remove default shadow
    &:not(.ant-table-has-fix-right) .ant-table-container::after {
      box-shadow: none;
    }

    // Override pointer cursor when there is no data.
    .ant-table-tbody {
      .ant-table-placeholder {
        td.ant-table-cell {
          cursor: default;
        }
      }
    }
  }

  .ant-table-cell-fix-left-last,
  .ant-table-cell-fix-right-first {
    &:after {
      display: none;
    }
  }

  .ant-table-cell.cell-actions {
    overflow: visible !important;
  }

  th.ant-table-cell {
    position: sticky;
    z-index: 2;
    color: #616f82;
    padding: 7px;

    &:after {
      content: attr(data-order-priority);
      position: absolute;
      top: 2px;
      right: 5px;
      font-size: 12px;
      color: $blue-6;
    }
  }

  .ant-table-thead {
    th.ant-table-column-has-sorters {
      padding: 10px 18px 10px 0;

      &:hover {
        background: #f5f5f5;
      }
    }
  }

  &.search-results {
    .ant-table-tbody {
      .ant-table-row:hover {
        & > td {
          .anticon {
            color: #000;

            &:hover {
              color: $blue-6;
            }
          }
        }
      }
    }
  }

  .ant-table-tbody {
    & > tr {
      & > td {
        padding: 7px;
        background-color: #fff;
        transition: background-color 0.25s ease-in;
      }

      &.ant-table-row {
        background: #fff;
        a.new-tab-number {
          .icon-open-in-new {
            display: none;
          }
          &:hover {
            .icon-open-in-new {
              display: inline;
              color: $blue-6;
            }
          }
        }
        td.ant-table-cell.not-interactive {
          cursor: default;
        }
      }

      &.ant-table-row.active-row {
        & > td {
          background-color: $blue-1;

          .icon-pen,
          .icon-open-in-new {
            color: #000;

            &:hover {
              color: $blue-6;
            }
          }
        }
      }

      &.ant-table-row:hover {
        & > td {
          background-color: $blue-1;

          .icon-pen,
          .icon-open-in-new {
            color: #000;

            &:hover {
              color: $blue-6;
            }
          }

          &.company_url {
            .anticon-global {
              color: #000;

              &:hover {
                color: inherit;
              }
            }
          }

          & > a.new-tab-number {
            .icon-open-in-new {
              color: $blue-6;
            }
          }
        }

        .icon-pen {
          visibility: visible;
        }

        .open-in-new {
          display: inline-block;
        }
      }
    }

    .anticon svg {
      width: 22px;
      height: 22px;
    }

    .company-name__profile-link {
      font-weight: 600;
      color: $blue-6;
      min-width: 100%;
    }

    .company-name__no-link {
      font-weight: 600;
      color: inherit;
    }

    .icon-open-in-new {
      width: 18px;
      height: 18px;
      margin-left: 10px;
    }
  }

  td.ant-table-cell:not(.sticky):not(.editable-cell) {
    cursor: pointer;
  }

  td {
    &.align-right {
      text-align: right;
    }

    &.align-center {
      text-align: center;
    }
  }

  // sorting
  .ant-table-column-sorters {
    padding: 0 0 0 7px;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .ant-table-column-has-sorters {
    .anticon {
      display: none;

      &.active {
        display: block;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .ant-table-column-sorter {
      position: absolute;
      top: 50%;
      margin-right: 8px;
      right: 0;
      transform: translate(0, -50%);
    }
  }

  .sticky {
    position: sticky;
    z-index: 1;
  }
}

.legacy-table:not(.et-integration) {
  .ant-table-tbody {
    .ant-table-cell:not(.contact-cell),
    .ant-table-cell:not(.contact-cell) > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: inherit;
    }

    .ant-table-cell {
      color: $gray-8;
    }

    .cell-growth {
      color: $green-6;
    }

    .empty-cell {
      opacity: 0;
    }
  }

  .overlap {
    position: relative;
    z-index: 3;
  }
}

.legacy-table.et-integration {
  .ant-table-tbody {
    .ant-table-cell {
      &.date_added_et,
      &.portfolio_date,
      &.from_optx_to_et {
        color: $gray-8;
      }
    }
  }
}

.legacy-table.et-integration,
.pipeline-table {
  .ant-table-tbody {
    .ant-table-row:hover {
      & > td {
        a > .anticon {
          color: #000;
        }
        a > .anticon:hover {
          color: $blue-6;
        }
        a.not-active > .anticon:hover {
          color: red;
        }
      }
    }
  }
}
