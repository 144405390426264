.company-kpis-wrapper {
  margin: 0 -24px;

  > .ant-row {
    background: #dbe5f2;
    padding: 18px 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
