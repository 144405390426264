@import '../../../assets/scss/antd/variables';
@import '../../../assets/scss/variables';

.my-companies {
  background: $bg-body;
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  color: $gray-8;

  .my-companies-second-header {
    padding: 15px 0;
    background-color: #f5f7fa;

    &__count {
      font-size: 20px;
      color: #8997b1;
    }
  }

  .main-content {
    margin-top: 20px;

    .my-companies-card-group {
      padding: 26px 0 5px;

      &__content > div {
        padding: 0 0 20px;
      }
    }
  }
}

.my-companies--filters {
  .equitytouch {
    .ant-checkbox-wrapper {
      cursor: default;
      pointer-events: none;

      > * {
        cursor: default;
        pointer-events: none;
      }
    }

    .ant-checkbox-inner {
      background-color: #f5f5f5;
      border-color: #d9d9d9 !important;

      &:after {
        border-color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
