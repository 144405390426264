.menu-collapsed {
  .recent-activity-modal.ant-drawer-open {
    left: 80px;
    width: calc(100% - 80px);
  }
}

.recent-activity-modal {
  &__content {
    height: 100%;
    overflow: auto;
    background: #f5f7fa;
  }

  &.modal-dialog {
    min-height: unset;
    height: 100%;
  }

  &.ant-drawer {
    z-index: 11;
  }
  &.ant-drawer-open {
    left: 256px;
    width: calc(100% - 256px);

    .ant-drawer-content-wrapper {
      left: 0;
      right: auto;
    }

    .ant-drawer-wrapper-body {
      background: #f5f7fa;
    }
  }
  &.ant-drawer.close-modal {
    opacity: 0;
  }

  .ant-drawer-header {
    background: #f5f7fa;

    .ant-drawer-title {
      font-weight: 500;
      color: #364862;
      line-height: 1.4;

      .notification-title {
        display: flex;
        align-items: center;
      }

      .roadmap {
        color: #1890ff;

        .map-icon {
          width: auto;
          height: auto;
          padding-right: 8px;

          path {
            fill: #1890ff;
          }
        }
      }

      .notifications-anticon-wrapper {
        display: inline-block;
        margin-right: 8px;
        font-weight: normal;
      }

      .ant-badge.ant-badge--notifications {
        right: 20%;
        top: calc(-100% + 9px);
      }
    }

    .ant-drawer-close {
      color: #364862;
      height: 65px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .ant-drawer-body {
    padding: 0;
    position: relative;

    .ant-tabs-nav {
      padding: 0 20px;
      margin-bottom: 0;
      z-index: 5;
      background: #fff;
    }

    .ant-tabs-top,
    .ant-tabs-content-holder,
    .ant-tabs-content-top {
      height: 100%;
      overflow: hidden;
    }

    .ant-tabs {
      background-color: #fff;
    }
  }

  .notification-body {
    background-color: #fff;
  }
}

.notification-item {
  .score-trending__label,
  .kpi__label {
    font-size: 12px;
    line-height: 1;
  }

  .score-trending {
    width: 85px;
  }

  .kpi {
    margin-right: 20px;
    text-align: center;
  }

  .kpi__value {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
  }

  .kpi__label {
    font-weight: 600;
  }

  &__block {
    overflow: hidden;
  }

  &__title {
    padding: 24px 0 25px 29px;
    line-height: 28px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #f0f0f0;

    &-details {
      color: #8997b1;
    }
  }

  &__inner {
    margin-bottom: 12px;
  }

  &__name {
    font-size: 16px;
    font-weight: normal;
    text-transform: capitalize;
    line-height: 1.2;
  }

  &__main {
    height: auto;
    padding: 20px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 0;
    transition: all 0.4s ease;
    position: relative;
    background-color: #fff;

    &:hover {
      background-color: #e6f7ff;

      .notification-item__top button {
        display: inline-block;
      }

      .notification-item__message-bottom button {
        display: inline-block;
      }
      .notification-item__message-history-bottom button {
        display: inline-block;
      }
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-tag {
      margin-right: 0;
      margin-top: 15px;
    }
  }

  &__top {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: flex-start;
  }

  &__history-top {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: flex-start;
  }

  &__avatar {
    width: 55px;
    height: 55px;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    box-shadow: 1px 1px 0px #f0f0f0;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    svg {
      width: 44px;
    }

    img {
      width: 100%;
      height: 55px;
      object-fit: contain;
    }

    .anticon-file-text,
    .anticon-filter {
      margin-top: 3px;
      font-size: 24px;
    }
  }

  &__info {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  &__history-info {
    overflow: hidden;
  }



  &__list-detail {
    font-weight: 600;
    color: #364862;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;

    span {
      color: #1890ff;
      display: block;
      text-align: right;
      font-size: 1rem;
      line-height: 1.5715;
    }
  }

  &__company-name > a span {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    color: #1890ff;
    display: inline-block;
    position: relative;
    max-width: 325px;
    z-index: 2;
  }

  &__message {
    font-size: 14px;
    color: #364862;
    line-height: 1.5;

    b {
      font-weight: 600;
    }

    span:nth-child(2) {
      display: block;
      font-size: 13px;
      opacity: 0.7;
      color: rgb(137, 151, 177);
    }

    span:nth-child(3) {
      display: block;
      margin-top: 11px;
    }

    &-bottom {
      display: flex;
      margin-top: 11px;
      justify-content: space-between;
      width: 33.5rem;

      & > span {
        max-width: 80%;
        min-height: 24px;
      }

      button {
        display: none;
      }
    }

    &-history-bottom {
      display: flex;
      margin-top: 11px;
      justify-content: space-between;

      & > span {
        max-width: 80%;
        min-height: 24px;
      }

      button {
        display: none;
      }
    }
  }
}
