.table-contacts-searches {
  overflow: auto;

  .ant-table-cell {
    overflow: hidden;
  }

  .cell-title,
  .cell-key {
    width: 100px;
  }

  .cell-filters {
    width: 300px;
  }

  .cell-actions {
    width: 60px;
  }

  .ant-tag {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.badge {
  &--search-filter {
    font-size: 1rem;
    margin: 2px;
    font-weight: normal;
  }
}

.media {
  &--saved-search {
    &:hover {
      cursor: pointer;
    }

    .media-left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: 0 10px 0 0;
      color: #8891a5;
      border-radius: 5px;
      background-color: #f4f4f8;
      border: 1px solid lightgray;

      .icon-search {
        width: auto;
        height: 16px;
      }
    }

    .media-heading {
      font-size: 15px;
      font-weight: 500;
      color: #1967ae;
      padding: 2px 0;
      margin: 0;
      line-height: 1.2;
    }
  }
}

.table {
  .rt-td {
    &--filters {
      white-space: normal;
    }
  }
}
