.external-scroll-wrapper {
  width: 100%;
  height: 20px;
  overflow: auto;
  position: fixed;
  bottom: 64px;
  z-index: 5;
  width: calc(100% - 336px);

  .external-scroll {
    height: 20px;
  }
}

.external-scroll-menu-collapsed {
  width: calc(100% - 160px)
}

.is-fullscreen .external-scroll-wrapper {
  width: calc(100% - 80px)
  }

