// Opportunity Presentaton tab
// TODO: when finding a solution regardion antd overring styles change important rules.
.card-deal-preparation {
  height: 100%;

  .ant-card-head-title {
    font-weight: bold;
    padding: 9px 0 13px 0;
  }

  .ant-card-head {
    border-bottom: none;
  }
}

.deal-preparation {
  padding-bottom: 40px;

  .ant-card-head {
    height: 50px;
    margin-bottom: 20px;
    .ant-card-head-title {
      line-height: 12px;
      font-size: 20px;
      font-weight: 600;
      color: #334d6e;
    }

    .ant-card-extra {
      padding: 3px 0;
    }
  }

  .financial-card {
    .ant-card-body {
      padding: 0;
    }

    .proprietary-info-cards {
      @media screen and(max-width:1023px) {
        padding-right: 22px !important;
        padding-left: 0 !important;
        margin-top: 22px;
      }
    }

    .kpi--negative .kpi__value,
    .financial-value .negative {
      display: flex;
      justify-content: flex-end;
      gap: 2px;
    }

    .financial-value {
      flex-wrap: wrap;
      div {
        display: flex;
        gap: 1px;
        flex-wrap: nowrap;
      }
    }
  }
}

.card-proprietary-info {
  .financials-table {
    .financial-value {
      flex-wrap: wrap;
    }
  }
}

.ebitda-value {
  width: 360px !important;

  input,
  .ant-row {
    max-width: 220px;
    width: 215px;
  }

  .error {
    width: 360px;
  }

  .profile-information__popover-buttons {
    padding-left: 227px;
  }
}
