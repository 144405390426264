@import '../../../../assets/scss/bootstrap/functions.scss';
@import '../../../../assets/scss/bootstrap/variables.scss';
@import '../../../../assets/scss/bootstrap/breakpoints.scss';

.company-avatar {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0px 1px 0px #d9d9d9, inset 0px -1px 0px #d9d9d9;
  border: 1px solid rgba(135, 145, 159, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  svg {
    width: 60%;
  }

  // Specific cases.
  // Profile banner avatar.
  &--profile {
    width: 80px;
    height: 80px;

    @include media-breakpoint-up(sm) {
      width: 100px;
      height: 100px;
    }

    @include media-breakpoint-up(md) {
      border-radius: 8px;
      width: 120px;
      height: 120px;
    }

    @include media-breakpoint-up(lg) {
      width: 130px;
      height: 130px;
    }

    @include media-breakpoint-up(xl) {
      width: 150px;
      height: 150px;
    }
  }
}
