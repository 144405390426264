.main-header {
  z-index: 10;
  transition: all 0.2s ease;

  &.ant-layout-header {
    /* Gray / gray-1 */
    background: #ffffff;
    /* Gray / gray-5 */
    border: 1px solid #d9d9d9;
    padding: 0 16px;
  }

  &.fixed {
    position: fixed;
    width: 100%;
  }

  & > * {
    line-height: 1;
  }

  // header search input
  .header-search {
    width: 250px;
    margin: 11px 0;
  }

  .header-search-notes {
    width: 350px;
    margin: 11px 0;
  }
}

.main-layout {
  &.ant-layout-has-sider {
    .main-header {
      &.fixed {
        position: sticky;
        right: 0;
        left: 0;
        top: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .main-header {
    &.ant-layout-header {
      padding: 0 40px;
    }
  }
}
