.tags-collapsable__wrapper {
  width: 100%;

  .not-collapsed__btn.btn--add-tag {
    margin-top: 4px;
  }

  .collapsed .rc-overflow-item-rest .ant-tag {
    cursor: pointer
  }
}

.not-collapsed {
  .company-tags {
    margin-top: 5px;
  }
}

.company-tags {
  position: relative;
  z-index: 999;
  width: 250px;
  margin-top: -3px;

  .multiselect-select__control {
    min-height: auto;

    .multiselect-select__value-container {
      height: auto;
      padding-top: 0;
      padding-bottom: 0;

      .multiselect-select__value-container__icon {
        top: 7px;
      }

      div:last-of-type {
        padding: 0;
      }
    }
  }

  .multiselect-select__indicator {
    padding: 0 6px;
  }
}
