// override styles that messes with antd like sanitize or bootstrap. Will be removed at a later time.

// override svg for icon buttons
.ant-btn-icon-only {
  .anticon {
    > svg {
      vertical-align: baseline;
    }
  }
}
