.dropdown {
  &--save-list {
    width: 290px;
    .ant-divider-horizontal {
      margin: 1rem 0;
    }
    .dropdown-header {
      font-size: 16px;
      color: #364862;
    }

    .dropdown-item--actions {
      cursor: context-menu;
      margin-bottom: 0;
      button + button {
        margin-left: 8px;
      }
    }
  }
}

.dropdown--audit-field {
  width: fit-content !important; 
  .edit-field--value,
  .profile-information__addon {
    display: block;
  }
}

.and-item-save-search-list .ant-form-item-explain li{
  display:block;
  white-space: normal;
}
