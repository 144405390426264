.main-layout {
  min-height: 100vh;
  background: #f5f6f8;

  &.ant-layout.ant-layout-has-sider {
    > .ant-layout {
      // Fix issue causing sticky children not to work (table).
      overflow-x: visible;
      width: 0;
    }
  }

  &.ant-layout-has-sider {
    // extended sider layout
    > .ant-layout {
      margin-left: 256px;
      transition: all 0.2s ease;
      min-height: 100vh;
    }

    // collapsed sider layout
    &.has-sider-collapsed {
      > .ant-layout {
        margin-left: 80px;
      }
    }
  }
}
