.empty-popover {
  &-trigger {
    display: block;
    // toggler needs at least 1px height. And to hide content use minus margin.
    height: 1px;
    margin-top: -1px;
    width: 100%;
    border: 0px;
    outline: 0px;
    pointer-events: none;
    z-index: -1;
  }

  &-wrapper {
    position: relative;
    background: inherit;
  }
}
