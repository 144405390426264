// mixin to include same horizontal spacing used by main container content.
@mixin mainContentSpacing {
  margin-left: 16px;
  margin-right: 16px;

  @include media-breakpoint-up(md) {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.main-content {
  @include mainContentSpacing();
}
