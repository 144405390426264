// temporary fix bootstrap overriding global antd styles on body.
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: $font-family;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
}
