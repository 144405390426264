.profile-hero {
  padding: 60px 0;
  background: linear-gradient(0deg, #064885 0%, #137edf 119.38%);

  > .ant-card {
    @include mainContentSpacing();

    border-radius: 8px;

    .ant-card-body {
      padding-bottom: 0;

      > .media-object {
        margin: 0 0 30px;

        > .media-object-section:first-child {
          margin-top: -60px;
        }
      }
    }
  }

  .score-trending {
    display: block;
    margin: 30px auto 0;

    &.psg-company {
      line-height: 1;
    }

    .trending-value {
      font-size: 20px;
      margin-top: 5px;
    }

    &__growth-score {
      font-size: 40px;
      line-height: 54px;
      font-weight: 800;
      letter-spacing: -0.02em;
    }

    &__label {
      font-weight: 700;
    }
  }

  .company-name {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 600;
    color: #007bfe;
    max-width: calc(100vw - 830px);

    a {
      color: #007bfe;
    }

    @media (max-width: 1274px) {
      font-size: 24px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (max-width: 414px) {
      display: block;
      text-align: center;
    }
    a {
      i {
        margin-left: 8px;
        font-size: 14px;
        color: #000;
        vertical-align: top;
      }
    }
  }

  .company-year-founded {
    font-weight: 600;
    color: #1890ff;
    line-height: 22px;
    .company-year-founded-edit {
      cursor: pointer;

      div{
        display: inline;
      }
      .edit-pen{
        margin-left: 4px;
        vertical-align: top;
        font-size: 14px;
        visibility: hidden;
      }
      &:hover .edit-pen{
        visibility: visible;
      }
    }
  }

  .tags-owner-row {
    max-width: 100%;
    min-height: 40px;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }
  }

  .company-source-descriptions {
    max-width: 800px;
    margin: 0 0 16px;
  }
}
