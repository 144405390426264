.date-picker-range {
  display: flex;

  // fix overflow on overflowed parents.
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  input {
    width: 100%;
  }

  input {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 6px 12px;
  }

  .react-datepicker-wrapper:first-child {
    margin: 0 0.8rem 0 0;
  }
}

// container for date picker used to show above modals.
.react-datepicker-popper {
  z-index: 1060;
}
