.contacts-grid {
  .country_code,
  .state_code,
  .city,
  .linkeding {
    min-width: 90px;
    max-width: 90px;
  }

  .first_name,
  .last_name,
  .title,
  .cb_rank {
    min-width: 110px;
    max-width: 110px;
  }

  .ceo_approval,
  .display_location {
    min-width: 120px;
    max-width: 120px;
  }

  .source_db,
  .tenure_start_date,
  .email,
  .phone {
    min-width: 160px;
    max-width: 160px;
  }

  .company_name {
    min-width: 240px;
    max-width: 240px;
  }
}

.grid-page {
  &:not(.fullscreen) {
    .contacts-grid {
      max-height: calc(100vh - 191px);
    }
  }
  &.fullscreen > .position-relative.fullscreen-contacts {
    margin-top: 4%;
  }
}
