@import '../../variables';
@import '../../../bootstrap/functions';
@import '../../../bootstrap/variables';
@import '../../../bootstrap/breakpoints';

.profile-chart-card {
  position: relative;
  $self: &;

  &__no-data {
    background: rgb(240, 240, 240);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(240, 240, 240, 1) 100%);
  }

  &__container {
    position: relative;
    width: 100%;
    height: 135px;

    &.has-legend {
      .chart-empty-labels {
        height: calc(100% - 80px);
      }
    }
  }

  .ant-card-head-title {
    font-size: 20px;
  }

  .ant-card-extra {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .recharts-wrapper {
    font-size: 10px;
  }

  .recharts-yAxis {
    font-size: 12px;
  }

  .recharts-xAxis {
    text-transform: uppercase;
  }

  &__trending {
    display: flex;
    align-items: center;

    .score-trending {
      display: grid;
      grid-template-rows: 1fr 1fr;
      height: inherit;
      margin-left: 30px;

      &.psg-company {
        grid-template-rows: 1fr;
      }
    }

    .score-trending__growth-score {
      font-size: 18px;
      line-height: 18px;
    }

    .trending-value {
      font-size: 12px;
    }

    .score-trending__label {
      font-weight: 600;
      font-size: 0.625rem;
      line-height: 2;
      text-transform: uppercase;
    }

    .score-trending__growth {
      display: flex;
      align-items: center;
      position: relative;
    }
  }

  &.us {
    #{$self}__trending {
      .kpi__label,
      .score-trending__label {
        font-size: 12px;
        letter-spacing: 0.02em;
      }
  
      .kpi__value {
        font-size: 14px;
        line-height: 22px;
      }

      .score-trending__growth-score {
        font-size: 24px;
        line-height: 24px;
        font-weight: 800;
        letter-spacing: 0.02em;
      }
    }

    .beta-score-extra {
      margin-top: -10px;
      margin-left: 250px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      position: relative;

      .score-trending__growth-score {
        color: #7CB305;
      }

      &.psg {
        margin-bottom: 15px;
      }
    }

    .info.psg {
      top: 17px;
      right: -5px;
    }
  }

  .kpi__value {
    line-height: 1.6em;
  }

  // styling for empty state
  &__empty {
    position: absolute;
    top: 0;
    left: 24px;
    width: calc(100% - 65px);
    height: calc(100% - 35px);
    display: flex;
    align-items: center;
    justify-content: center;

    .gray-rectangle-button {
      cursor: default;
    }
  }

  .chart-empty-labels {
    background: linear-gradient(180deg, #f0f0f0 -11.27%, rgba(196, 196, 196, 0) 100%);
    height: calc(100% - 35px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-right: 1px solid $blue-6;
  }

  // tooltip
  &__tooltip {
    height: 100%;
    padding: 5px 8px;
    font-size: 12px;
    background: $gray-9;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    color: #fff;
  }
}

.score-chart,
.employee-chart {
  .ant-card-body {
    padding-left: 0;
  }
}

@include media-breakpoint-down(md) {
  .profile-chart-card {
    .kpi {
      margin-left: 10px;
    }

    .kpi__label {
      max-width: 50px;
      line-height: normal;
    }
  }
}
