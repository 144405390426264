// Color variables
$white: #ffffff;

$primary: #5b6be8;
$success: #3cab94;
$info: #40a4f1;
$warning: #f5b225;
$danger: #ec536c;

$dark: #2d3b48;
$muted: #707070;
$greylight: #c1c5e2;

$lightdark5: #555555;
$lightgray: #dddddd;
$light: #eff3f6;
$lightdark3: #333333;
$lightdark4: #444444;
$light5: #f5f5f5;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark7: #797979;
$lightdark-alt: #cccccc;

$text-red: #f02b2b;
$text-green: #39c65e;

$bg-body: #f5f7fa;
$left-bar: #ffffff;
$bg-topbar: #5b6be8;

$shadow: 1px 0px 20px rgba(0, 0, 0, 0.05);

$base-font: 15px;

// Width variables
$width: 100%;

// Height variables
$height: 100%;
