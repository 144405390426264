.main-breadcrumb {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .ant-breadcrumb-link {
    button {
      font-weight: 600;
    }
  }

  &--inverted {
    color: rgba(255, 255, 255, 0.45);

    .ant-breadcrumb-separator {
      color: rgba(255, 255, 255, 0.45);
      color: hsla(0,0%,100%,.85);
    }

    // get the last 2 spans and set separator to white.
    & span:nth-child(-n + 2) {
      .ant-breadcrumb-separator {
        color: rgba(255, 255, 255, 0.85);
      }
    }

    & li:last-child span,
    & > span:last-child {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}
