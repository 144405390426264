.dropdown {
  &-menu {
    &--multiselect {
      max-height: 50vh;
      min-height: 300px;
      overflow: auto;

      .dropdown-item {
        padding: 0;

        // &:first-child {
        //   display: flex;

        //   &:hover {
        //     background-color: initial;
        //   }
        // }
      }

      .checkbox {
        margin: 0;
        padding: 0.55rem 38px;

        .checkmark {
          left: 0.8rem;
        }
      }
    }
  }
}

// .search-control {
//   margin: 6px 12px;
// }

.dropdown-multiselect {
  &__toggle {
    color: inherit;
    background: inherit;

    &:hover,
    &:focus {
      color: inherit;
      background: inherit;
    }
  }
}
