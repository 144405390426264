/* ==============
  Menu
===================*/
.container-fluid {
  width: 95%;
}

/*
  Responsive Menu
*/
@media (max-width: 991px) {
  .container-fluid {
    width: 100%;
  }
}

@media (max-width: 620px) {
  .logo-large {
    display: none;
  }

  .logo-small {
    display: inline-block !important;
  }
}
