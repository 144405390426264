///Start table///
.table-group {
  margin-bottom: 5px;
  width: 100%;
  .card-body {
    @media only screen and (max-width: 768px) {
      padding: 1rem;
    }
    @media only screen and (max-width: 414px) {
      padding: 0.8rem 0;
    }
  }
  .table-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.25rem;
    padding-bottom: 0;
    .table-title {
      font-size: 24px;
      color: #364862;
    }
    .table-actions {
      display: flex;
      align-self: flex-end;
    }
  }

  .td-hide {
    display: none;
  }

  .table-wrap {
    position: relative;
  }

  .row-table {
    text-align: center;
    color: #495057;

    .table-cell {
      &--company-name {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 1;
      }
    }
  }
  .table-bottom {
    text-align: center;
  }
}

.grid-pagination {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  z-index: 6;
  padding: 21px 16px;
  border-radius: 0 0 4px 4px;
  background: #fff;
  transition: all 0.2s ease;
  z-index: 100;

  &--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--per-page {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
}

// pagination styles with sider collapsed or not collapsed.
.main-layout {
  &.ant-layout-has-sider {
    .grid-pagination {
      width: calc(100% - 256px);
    }

    &.has-sider-collapsed {
      .grid-pagination {
        width: calc(100% - 80px);
      }
    }
  }
}

///End table///
.company-count {
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 24px;
  color: #364862;
}

@media only screen and (max-width: 600px) {
  .table-group.table-main {
    .table-header {
      flex-direction: column;
      padding: 1rem 1rem 0 1rem;
      .table-title {
        font-size: 20px;
      }
      .table-actions {
        margin-top: 10px;
      }
    }
  }

  .company-count {
    font-size: 20px;
  }
}

@include media-breakpoint-up(md) {
  .grid-pagination {
    padding: 21px 40px;
  }
}
